<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8" lg="6">
        <v-row justify="center" class="ma-0">
          <v-col align="center" class="pa-0">
            <v-card elevation="0" rounded="xl" width="140" height="120">
              <v-card-text>
                <v-img
                  :src="require('../../assets/voe_imgs/logo.png')"
                  alt="Logo"
                  width="120"
                  height="100"
                ></v-img>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col class="pa-0" align="center">
            <h2>WE ALLIANCE SPARRKLE</h2>
          </v-col>
        </v-row>
        <v-row justify="center" class="ma-0">
          <v-col align="center" class="pa-0">
            <v-img
              :src="require('../../assets/voe_imgs/login-image.png')"
              alt="Login Image"
              max-width="180"
            ></v-img>
          </v-col>
        </v-row>
        <v-row justify="center" class="ma-0">
          <v-col class="pa-0">
            <h2 :style="`color:#2B8FDA`">Welcome Back!</h2>
            <span class="text-subtitle">Please sign in to continue</span>
          </v-col>
        </v-row>
        <v-form ref="loginForm" v-model="valid" class="mx-2">
          <v-row justify="center" class="ma-0">
            <v-col class="pa-0">
              <h5 class="text-h6">Login ID</h5>
              <v-text-field
                style="height: 55px"
                solo
                dense
                rounded
                v-model="form.email"
                placeholder="Login ID"
                prepend-inner-icon="mdi-account"
                outlined
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" class="ma-0">
            <v-col class="pa-0">
              <h5 class="text-h6">Password</h5>
              <v-text-field
                style="height: 55px"
                solo
                dense
                rounded
                v-model="form.password"
                outlined
                placeholder="Password"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :error-messages="errors.password"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="ma-0">
            <!-- <v-col class="pa-0" align="center">
              <v-checkbox hide-details="" v-model="form.rememberme"
                >Remember Me</v-checkbox
              >
            </v-col> -->
            <v-col class="pa-0" align="right">
              <v-btn text to="/forgot-password">Forgot Password?</v-btn>
            </v-col>
          </v-row>

          <v-row class="ma-0" justify="center">
            <v-col class="pa-0 mt-3" align="center">
              <v-btn
                min-width="250"
                @click="login"
                :loading="isLoading"
                color="blue"
                rounded
                max-width="100"
                dark
                >Sign In</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      valid: true,
      showPassword: false,
      isLoading: false,
      form: {
        email: "",
        password: "",
        rememberme: false,
      },
    };
  },
  methods: {
    ...mapActions({
      logIn: "auth/logIn",
    }),
    async login() {
      this.isLoading = true;
      this.logIn(this.form)
        .then(() => {
          location.reload("/home");
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
