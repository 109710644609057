<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
            min-height="430"
          >
            <v-row class="ma-0" justify="center">
              <v-col class="pa-0 mt-4 ml-4">
                <h3 style="width: 260px">
                  Training Videos Details of {{ course.course_name }}
                </h3>
              </v-col>
              <v-col class="pa-0 ma-2">
                <v-fab-transition>
                  <v-btn
                    color="blue lighten-5"
                    :to="`/training-video/${course.id}/details/create`"
                    dark
                    absolute
                    fab
                    width="50"
                    height="50"
                  >
                    <v-icon color="blue">mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
              </v-col>
            </v-row>
            <template v-if="course_details.length > 0">
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-card
                    rounded="xl"
                    class="custom-border px-4 mt-2 mb-4 mx-3"
                    elevation="10"
                    v-for="course_detail in course_details"
                    :key="course_detail.id"
                  >
                    <!-- <router-link :to="course.router" style="text-decoration: none"> -->
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0 ma-2">
                        <v-row align="center" class="ma-0">
                          <v-col class="pa-0 ma-2">
                            <span class="text-subtitle-1 font-weight-medium">
                              Title: {{ course_detail.title }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-row align="center" class="ma-0">
                          <v-col class="pa-0 ma-2">
                            <span class="text-subtitle-1 font-weight-medium">
                              Description: {{ course_detail.description }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-row align="center" class="ma-0">
                          <v-col class="pa-0 ma-2">
                            <span class="text-subtitle-1 font-weight-medium">
                              Video Link: {{ course_detail.videolink }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-col class="pa-0 ma-2" align="right">
                          <v-btn
                            class="mx-2"
                            color="blue lighten-5"
                            :to="`/training-video/${course.id}/details/${course_detail.id}`"
                            dark
                            fab
                            right
                            width="40"
                            height="40"
                          >
                            <v-icon color="blue" size="20">mdi-pencil</v-icon>
                          </v-btn>
                        </v-col>
                      </v-col>
                    </v-row>
                    <!-- </router-link> -->
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template v-if="course_details.length == 0">
              <v-row justify="center">
                <v-col align="center" style="min-height: 100px">
                  <v-progress-circular
                    v-if="isLoading == true"
                    :size="70"
                    :width="7"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                  <span v-else> No training video details found. </span>
                </v-col>
              </v-row>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      course: {},
      course_details: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let course = await axios.get(`/courses/${this.$route.params.courseId}`);
      this.course = course.data.data;
      let course_details = await axios.get(
        `/courses/${this.$route.params.courseId}/course_details?search=all`
      );
      this.course_details = course_details.data.data;
      // this.count = course_details.data.count;
      // this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
