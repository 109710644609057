import Vue from "vue";
import moment from "moment";

Vue.filter("ddmmyyyy", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("DD-MM-YYYY");
});
Vue.filter("DDMMMYYYY", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("DD MMM, YYYY");
});
Vue.filter("MMddyyyy", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("MMM DD, YYYY hh:mm A");
});
Vue.filter("mm", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("MMMM");
});

Vue.filter("hhmm", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("hh:mm:ss A");
});

Vue.filter("wordCount", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.split(" ").length;
});

Vue.filter("twoDec", function (value) {
  if (value == "0") return 0;
  if (!value) return "";
  // value = value.toString();
  return parseFloat(value).toFixed(2);
});

Vue.filter("limit", function (string = "") {
  if (string.length > 40) return string.substring(0, 40) + "...";
  return string;
});

Vue.filter("limit30", function (string = "") {
  if (string.length > 30) return string.substring(0, 30) + "...";
  return string;
});
Vue.filter("limit25", function (string = "") {
  if (string.length > 25) return string.substring(0, 25) + "...";
  return string;
});

Vue.filter("capitalize", function (string = "") {
  // if (string.length > 25) return string.substring(0, 25) + "...";
  if (string) {
    let capitalized_string =
      string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    return capitalized_string;
  }
});

var filter = function (text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter("truncate", filter);

Vue.filter("in_days", function (value) {
  if (!value) return "";
  let date = moment(value);
  if (moment().diff(date, "days") >= 2) {
    return date.fromNow(); // '2 days ago' etc.
  }
  return date.calendar().split(" ")[0];
});

Vue.filter("secToHhMm", function (value) {
  if (value == "" || value == null) return;
  var sec_num = parseInt(value, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
});
