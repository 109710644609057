<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0">
          <!-- Main Container -->
          <v-card class="mt-4" rounded="xl" color="blue lighten-5" width="auto">
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="pt-4">
                <v-col>
                  <v-toolbar-title class="blue--text">
                    Company Vision
                  </v-toolbar-title>
                </v-col>
              </v-row>

              <!-- Category Cards -->
              <v-row class="pa-0">
                <!-- <v-col v-for="category in categories" :key="category.title"> -->
                <v-col class="bold" justify="center">
                  <h3 class="pa-0">
                    “To create a healthy, progressive and supportive
                    environment.”
                  </h3>
                </v-col>
              </v-row>
              <v-divider class="mx-4 grey"></v-divider>
              <v-row class="pt-4">
                <v-col>
                  <v-toolbar-title class="blue--text">
                    Company Mission
                  </v-toolbar-title>
                </v-col>
              </v-row>

              <!-- Category Cards -->
              <v-row class="pa-0">
                <!-- <v-col v-for="category in categories" :key="category.title"> -->
                <v-col class="bold" justify="center">
                  <h3 class="pa-0">
                    To create extraordinary from and ordanary individual.
                  </h3>
                </v-col>
              </v-row>
              <v-divider class="mx-4 grey"></v-divider>
              <v-row class="pt-4">
                <v-col>
                  <v-toolbar-title class="blue--text">
                    Company Values
                  </v-toolbar-title>
                </v-col>
              </v-row>
              <v-row class="pa-0">
                <!-- <v-col v-for="category in categories" :key="category.title"> -->
                <v-col class="bold" justify="center">
                  <h3 class="pa-0">Relationship | Growth | Happiness</h3>
                </v-col>
              </v-row>
              <!-- <v-row class="pa-0" justify="center">
                <v-col align="center" class="ma-0">
                  <v-img
                    :src="require('../../assets/voe_imgs/company-vision.png')"
                    alt="Logo"
                    width="200"
                    height="190"
                  ></v-img>
                </v-col>
              </v-row> -->
              <v-row justify="center">
                <v-col class="text-decoration-underline" align="center">
                  View complete document
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>
<style scoped>
.custom-border {
  border-color: #2b8fda; /* Replace with your desired border color */
}
</style>
