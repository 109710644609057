<template>
  <div>
    <v-app-bar color="blue" dark fixed app height="60" w>
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer = !drawer"
        color=""
      ></v-app-bar-nav-icon>

      <v-toolbar-title color="black"
        >Hi,
        {{
          user.full_name != null ? user.full_name : user.email
        }} - {{ user.roles[0].name  }}</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-avatar size="50" v-if="$route.name != 'HomePage'">
        <v-img src="@/assets/voe_imgs/logo.png"></v-img>
      </v-avatar>
      <div v-else style="min-width: 80px">
        <router-link to="/settings">
          <v-icon class="pa-2">mdi-cog-outline</v-icon>
        </router-link>

        <v-icon class="pa-2">mdi-bell-outline</v-icon>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      left
      temporary
      color="blue lighten-5"
      app
      absolute
      height="580"
      class="mt-12 rounded-r-xl"
    >
      <v-list-item class="pa-3">
        <v-list-item-title align="center">
          <p>
            <span class="title">{{ user.full_name }}</span>
            <br />
            Emp Code:
            <span class="blue--text mt-2">{{ user.employee_id }}</span>
            <br />
            <span>
              {{ user.roles[0].name }}
            </span>
          </p>
        </v-list-item-title>
      </v-list-item>
      <v-list-item to="/home">
        <v-list-item-icon>
          <v-img
            :src="require('../../assets/voe_imgs/home.png')"
            alt="Login Image"
            max-width="50"
          ></v-img>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="profile">
        <v-list-item-icon>
          <v-img
            :src="require('../../assets/voe_imgs/profile.png')"
            alt="Login Image"
            max-width="50"
          ></v-img>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>My Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="dialog = true">
        <v-list-item-icon>
          <v-img
            :src="require('../../assets/voe_imgs/logout.png')"
            alt="Login Image"
            max-width="50"
          ></v-img>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-row justify="center" class="ma-0">
        <v-col align="center" class="pa-0">
          <v-img
            :src="require('../../assets/voe_imgs/logout-screen.png')"
            alt="Login Image"
            max-width="200"
          ></v-img>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card rounded="xl">
        <v-card-title class="text-h5" align="center">
          Are you sure you want to logout?
        </v-card-title>

        <v-card-actions justify="center">
          <v-row justify="center" class="my-4">
            <v-btn
              color="blue darken-1"
              rounded
              @click="logout"
              class="mx-2"
              dark
            >
              Yes
            </v-btn>
            <v-btn
              color="blue darken-1"
              rounded
              @click="dialog = false"
              class="mx-2"
              dark
            >
              No
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      drawer: false,
      group: null,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      console.log("Called");
      this.logOut().then(() => {
        window.history.pushState({}, "", "/"); // Clear historys

        location.replace("/login");
      });
    },
  },
};
</script>
