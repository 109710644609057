<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8" lg="6">
        <v-row justify="center" class="ma-0">
          <v-col align="center" class="pa-0">
            <v-card elevation="0" rounded="xl" max-width="160" max-height="150">
              <v-card-text>
                <v-img
                  :src="require('../../assets/voe_imgs/logo.png')"
                  alt="Logo"
                  width="110"
                  height="110"
                ></v-img>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col align="center">
            <h2>WE ALLIANCE SPARRKLE</h2>
          </v-col>
        </v-row>
        <v-row justify="center" class="ma-0">
          <v-col align="center" class="pa-0">
            <v-img
              :src="require('../../assets/voe_imgs/login-image.png')"
              alt="Login Image"
              max-width="220"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col>
            <h2 :style="`color:#2B8FDA`">Check your login id here!</h2>
            <!-- <span class="text-subtitle">Please sign in to continue</span> -->
          </v-col>
        </v-row>
        <v-form ref="loginForm" v-model="valid" class="mx-2">
          <v-row justify="center" class="ma-0">
            <v-col class="pa-0">
              <h5 class="text-h6">Login ID</h5>
              <!-- hide-details="" -->
              <v-text-field
                solo
                dense
                rounded
                v-model="form.email"
                placeholder="Login ID"
                prepend-inner-icon="mdi-account"
                outlined
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center" class="ma-0">
            <v-col align="center" class="pa-0">
              <v-btn
                min-width="250"
                @click="checkUser"
                :loading="isLoading"
                color="blue"
                rounded
                max-width="100"
                dark
                >Check login id</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      valid: true,
      isLoading: false,
      form: {
        email: "",
      },
    };
  },
  methods: {
    async checkUser() {
      this.isLoading = true;
      let query = "email=" + this.form.email;
      let existing_user = await axios.get(`/forgot_password?${query}`);
      this.existing_user = existing_user.data.data[0];
      console.log(this.existing_user);
      if (this.existing_user.length != 0) {
        this.$router.push({
          path: "/reset-password",
          query: { email: `${this.existing_user.email}` },
        });
        // this.$router.push(`/reset-password/${this.existing_user.email}`);
      }
      this.isLoading = false;
    },
  },
};
</script>
