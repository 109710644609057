<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-toolbar-title>Month: </v-toolbar-title>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-3">
                      <v-select
                        solo
                        dense
                        rounded
                        hide-details=""
                        v-model="selectedFromMonth"
                        :items="months"
                        label="Month"
                      ></v-select>
                      <!-- @change="updateCalendar" -->
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pa-0">
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-toolbar-title>Year: </v-toolbar-title>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-select
                        solo
                        dense
                        rounded
                        hide-details=""
                        v-model="selectedYear"
                        :items="years"
                        label="Year"
                      ></v-select>
                      <!-- @change="updateCalendar" -->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0 mx-2 mt-4">
                  <v-toolbar-title> Select Location: </v-toolbar-title>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0 mx-2 mb-1" style="max-width: 166px">
                  <v-select
                    solo
                    dense
                    rounded
                    hide-details=""
                    v-model="selectedLocation"
                    :items="locations"
                    label="Location"
                  ></v-select>
                  <!-- @change="updateCalendar" -->
                </v-col>
              </v-row>
              <hr class="mx-2 mt-5" />
              <template>
                <v-row justify="center" class="ma-0">
                  <v-col align="center" class="pa-0 mt-2">
                    <span>Tax Invoice</span>
                  </v-col>
                </v-row>
                <v-row justify="center" class="ma-0">
                  <v-col align="center" class="pa-0">
                    <v-carousel
                      v-model="model"
                      style="height: 180px !important"
                      hide-delimiters
                    >
                      <v-carousel-item v-for="color in colors" :key="color">
                        <v-sheet
                          class="fill-height"
                          :color="color"
                          height="100%"
                          tile
                          style="height: 180px !important"
                        >
                          <!-- <v-row align="center" justify="center">
                            <div class="text-h6 mt-4 mb-11">
                              Month {{ i + 1 }}
                            </div>
                          </v-row>
                          <v-row align="center" justify="center">
                            <div class="text-h5 mt-12">
                              Emp Name {{ i + 1 }}
                            </div>
                          </v-row> -->
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
              </template>
              <hr class="mx-2 mt-5" />
              <v-row class="ma-0 mt-2 mx-2">
                <v-col class="pa-0">Invoice Date:</v-col>
                <v-col class="pa-0">17 November 2023</v-col>
              </v-row>
              <v-row class="ma-0 mt-2 mx-2">
                <v-col class="pa-0">Due Date:</v-col>
                <v-col class="pa-0">17 November 2023</v-col>
              </v-row>
              <v-row class="ma-0 mt-2 mx-2">
                <v-col class="pa-0">Payment Recevie Date:</v-col>
                <v-col class="pa-0">17 November 2023</v-col>
              </v-row>
              <v-row class="ma-0 mt-2 mx-2">
                <v-col class="pa-0">Payment Recevie Amount:</v-col>
                <v-col class="pa-0 blue--text">Rs. 14000.00</v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      selectedFromMonth: "",
      selectedToMonth: "",
      selectedYear: "",
      months: [
        { text: "January", value: "01" },
        { text: "February", value: "02" },
        { text: "March", value: "03" },
        { text: "April", value: "04" },
        { text: "May", value: "05" },
        { text: "June", value: "06" },
        { text: "July", value: "07" },
        { text: "August", value: "08" },
        { text: "September", value: "09" },
        { text: "Octomber", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" },
      ],
      years: [
        { text: "2022", value: "2022" },
        { text: "2023", value: "2023" },
        { text: "2024", value: "2024" },
      ],
      locations: [
        { text: "2022", value: "2022" },
        { text: "2023", value: "2023" },
        { text: "2024", value: "2024" },
      ],
      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
      model: 0,
    };
  },
  mounted() {
    // console.log(this.selectedYear);
  },
  methods() {},
};
</script>
<style scoped>
.custom-border {
  border-color: #ff0000; /* Replace with your desired border color */
}
</style>
