<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="t-xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="pt-4">
                <v-col class="ma-0">
                  <v-toolbar-title class="blue--text text-subtitle-1">
                    Site:
                  </v-toolbar-title>
                </v-col>
                <v-col class="ma-0">
                  <span class="text-subtitle-1">
                    {{ this.user.sites[0].name }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <span class="blue--text text-subtitle-1">
                    Supervisor Name:
                  </span>
                </v-col>
                <v-col>
                  <span>
                    {{ this.user.full_name }}
                  </span>
                </v-col>
              </v-row>

              <!-- Category Cards -->
              <v-card
                rounded="xl"
                class="custom-border px-4 my-6"
                elevation="10"
                color="blue lighten-2"
              >
                <v-row align="center">
                  <v-col>
                    <v-simple-table
                      height="300px"
                      style="background-color: #64b6f6 !important"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left black--text pr-0" dense>
                              Sr.No
                            </th>
                            <th class="text-left black--text pr-0">
                              Particulars
                            </th>
                            <th class="text-left black--text pr-0">Adv</th>
                            <th class="text-left black--text">Extra</th>
                          </tr>
                        </thead>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card>

              <v-row justify="center">
                <v-col align="center">
                  <v-img
                    :src="require('../../assets/voe_imgs/form.png')"
                    alt="Logo"
                    width="180"
                    height="180"
                  ></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container> -->
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      RequestFormTypes: [
        {
          title: "Leave Application",
          image: require("@/assets/voe_imgs/leave.png"),
        },
        {
          title: "Salary Advance",
          image: require("@/assets/voe_imgs/salary.png"),
        },
        {
          title: "Uniform request",
          image: require("@/assets/voe_imgs/uniform.png"),
        },
        {
          title: "ID card request",
          image: require("@/assets/voe_imgs/id-card.png"),
        },
        {
          title: "Shoe request",
          image: require("@/assets/voe_imgs/shoe.png"),
        },
      ],
    };
  },
};
</script>
<style scoped>
.custom-border {
  border-color: #ff0000; /* Replace with your desired border color */
}
</style>
