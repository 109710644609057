<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0">
          <!-- Main Container -->
          <v-card class="mt-4" rounded="xl" color="blue lighten-5" width="auto">
            <!-- Dashboard Container -->
            <v-container>
              <!-- Category Cards -->
              <v-row class="pa-0">
                <!-- <v-col v-for="category in categories" :key="category.title"> -->
                <v-col class="bold" justify="center">
                  {{ user.sites[0].code_of_conduct }}
                  <!-- <h4 class="pa-0 blue--text mt-4">Compliance with law</h4>
                  <p>
                    All WeAlliance employees are required to protect our
                    company’s legality, therefore they should comply with all
                    environmental, safety and fair dealing laws. We do expect
                    employees to be ethical and responsible whenever dealing
                    with our company finances, products, partnerships and public
                    image.
                  </p>
                  <h4 class="pa-0 blue--text mt-4">Respect in the workplace</h4>
                  <p>
                    All employees of WeAlliance are expected to respect their
                    colleagues. We will not allow for any kind of discriminatory
                    behavior, harassment or victimization. Employees should also
                    conform with the equal opportunity policy in all aspects of
                    their job duties.
                  </p>
                  <h4 class="pa-0 blue--text mt-4">
                    Protection of company property
                  </h4>
                  <p>
                    All employees are expected to treat WeAlliance property with
                    respect and care. This means that employees:
                  </p>
                  <ul>
                    <li>
                      Should not misuse company equipment or frivolously use it.
                    </li>
                    <li>
                      Should respect all incorporeal property such as
                      trademarks, copyright and other property. Employees
                      should, however, use them to complete their job duties
                      only.
                    </li>
                    <li>
                      Should protect the company facilities and material
                      property from damage and vandalism.
                    </li>
                  </ul> -->
                </v-col>
              </v-row>

              <v-row justify="center" class="ma-0">
                <v-col class="text-decoration-underline pa-0" align="right">
                  View complete document
                </v-col>
              </v-row>
              <v-row justify="center" class="ma-0">
                <v-col class="pa-0">
                  <!-- v-model="checkbox" -->
                  <v-checkbox
                    :label="`I agree to the Code of Conduct`"
                    value="1"
                    v-model="form.is_agreed_coc"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row justify="center" class="ma-0">
                <v-col align="center" class="pa-0">
                  <!-- :loading="isLoading" -->
                  <v-btn
                    @click="save"
                    min-width="250"
                    color="blue"
                    rounded
                    max-width="100"
                    dark
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {},
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    async save() {
      this.isLoading = true;
      // this.form.is_agreed_coc
      this.form.is_agreed_coc = this.form.is_agreed_coc ? 1 : 0;
      await axios.patch(`/users/${this.user.id}`, this.form);
      this.$router.push("/home");
      this.isLoading = false;
    },
    async getData() {
      this.isLoading = true;
      // this.form.is_agreed_coc
      let form = await axios.get(`/users/${this.user.id}`);
      this.form = form.data.data;
      console.log(this.form);
      this.form.is_agreed_coc = this.form.is_agreed_coc == 1 ? "1" : "0";
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.custom-border {
  border-color: #2b8fda; /* Replace with your desired border color */
}
</style>
