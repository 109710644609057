<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0">
          <!-- Main Container -->
          <v-card class="mt-4" rounded="xl" color="blue lighten-5" width="auto">
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="pt-4">
                <v-col>
                  <v-toolbar-title class="blue--text">
                    Safety Videos
                  </v-toolbar-title>
                </v-col>
                <v-col
                  align="right"
                  class="pr-4"
                  v-if="safety_videos.length > 4"
                >
                  <span class="text-subtitle blue--text">View All</span>
                </v-col>
              </v-row>

              <!-- Category Cards -->
              <v-row class="pb-5">
                <!-- <v-col v-for="category in categories" :key="category.title"> -->
                <template v-if="safety_videos.length > 0">
                  <v-col
                    md="6"
                    sm="6"
                    xs="6"
                    v-for="safety_video in safety_videos"
                    :key="safety_video.id"
                  >
                    <v-card
                      outlined
                      height="138"
                      width="150"
                      rounded="xl"
                      elevation="10"
                      class="custom-border"
                    >
                      <!-- <v-card-text align="center"> -->
                      <iframe
                        height="136"
                        width="148"
                        :src="`https://www.youtube.com/embed/${safety_video.video_url}`"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                      <!-- <v-img
                      src="https://via.placeholder.com/139x127"
                      alt="Safety Emergency"
                      fill
                    ></v-img> -->
                      <!-- </v-card-text> -->
                    </v-card>
                    <v-col class="mt-2">
                      <v-row>
                        <v-col md="8" sm="8" class="ma-0 pa-0">{{
                          safety_video.title
                        }}</v-col>
                        <v-col
                          md="4"
                          sm="4"
                          class="blue--text ma-0 pa-0"
                          align="right"
                          >{{
                            safety_video.time != null ? safety_video.time : ""
                          }}</v-col
                        >
                      </v-row>
                    </v-col>
                  </v-col>
                </template>
                <template v-else>
                  <v-row justify="center" class="py-3">
                    <v-col align="center">
                      <span> No Safety Video Found.</span>
                    </v-col>
                  </v-row>
                </template>
              </v-row>
            </v-container>

            <hr class="mx-4 mb-3 grey" />

            <v-row class="ma-0">
              <v-col class="px-5 blue--text pa-0">
                Emergency Contact Details:
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col md="2" lg="2" sm="2" class="px-5 blue--text pa-0">
                <v-img
                  :src="require('../../assets/voe_imgs/mark-location.png')"
                  alt="Safety Emergency"
                  max-height="50"
                  max-width="50"
                ></v-img>
              </v-col>
              <v-col md="10" lg="10" sm="10" class="px-5 blue--text pa-0">
                Current Location : Sec 08, Nerul(E)
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="px-5 blue--text pa-0">Hospital near me: </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col md="10" lg="10" sm="10" class="mx-2 pa-0">
                <span>Apollo Hospitals</span>
                <span>MGM Hospital</span>
              </v-col>
              <v-col md="10" lg="10" sm="10" class="mx-2 pa-0">
                <span>022-61676167</span>
                <span>022-27745500</span>
              </v-col>
              <v-col md="2" lg="2" sm="2" class="mx-2 pa-0">
                <v-img
                  :src="require('../../assets/voe_imgs/hospital.png')"
                  alt="Safety Emergency"
                  max-height="50"
                  max-width="50"
                ></v-img>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="px-5 blue--text pa-0">Fire Station near me: </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col md="10" lg="10" sm="10" class="mx-2 pa-0">
                <span>Vashi Fire Station</span>
              </v-col>
              <v-col md="10" lg="10" sm="10" class="mx-2 pa-0">
                <span>101/022-39393939</span>
              </v-col>
              <v-col md="2" lg="2" sm="2" class="mx-2 pa-0">
                <v-img
                  :src="require('../../assets/voe_imgs/fire-house.png')"
                  alt="Safety Emergency"
                  max-height="50"
                  max-width="50"
                ></v-img>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="px-5 blue--text pa-0"
                >Police Station near me:
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col md="10" lg="10" sm="10" class="mx-2 pa-0">
                <span>Vashi police Station</span>
              </v-col>
              <v-col md="10" lg="10" sm="10" class="mx-2 pa-0">
                <span>100/022-27734412</span>
              </v-col>
              <v-col md="2" lg="2" sm="2" class="mx-2 pa-0 mb-4">
                <v-img
                  :src="require('../../assets/voe_imgs/security-house.png')"
                  alt="Safety Emergency"
                  max-height="50"
                  max-width="50"
                ></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      safety_videos: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let safety_videos = await axios.get(`safety_videos`);
      this.safety_videos = safety_videos.data.data;
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.custom-border {
  border-color: #2b8fda; /* Replace with your desired border color */
}
</style>
