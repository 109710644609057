<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-12" rounded="xl">
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="ma-0">
            <h5 class="text-h6" align="left">Select Dates</h5>
            <v-dialog
              ref="ApplicationDae"
              :close-on-content-click="false"
              v-model="date_menu"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dates"
                  label="Date"
                  readonly
                  solo
                  hide-details=""
                  dense
                  rounded
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range></v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-2 my-2">
          <v-col class="ma-0">
            <h5 class="text-h6" align="left">Select schedulers</h5>
            <v-autocomplete
              v-model="form.scheduler_id"
              :items="schedulers"
              rounded
              dense
              solo
              hide-details=""
              label="Schedulers"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="ma-0">
            <h5 class="text-h6" align="left">Select clients</h5>
            <v-autocomplete
              v-model="form.site_id"
              :items="sites"
              rounded
              dense
              solo
              hide-details=""
              label="Clients"
              @change="getSiteWiseEmployees"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="ma-0">
            <h5 class="text-h6" align="left">Select employees</h5>
            <v-autocomplete
              v-model="form.employee_id"
              :items="employees"
              rounded
              dense
              solo
              hide-details=""
              label="Employees"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col align="center">
            <v-btn
              @click="save"
              min-width="200"
              :loading="isLoading"
              color="blue"
              rounded
              max-width="100"
              dark
              >Assign</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card rounded="xl">
        <v-card-title class="text-h5" align="center">
          {{ msg }}
        </v-card-title>

        <v-card-actions justify="center">
          <v-row justify="center" class="my-4">
            <v-btn
              color="blue darken-1"
              rounded
              @click="dialog = false"
              class="mx-2"
              dark
            >
              Ok
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "SafetyVideos",
  data() {
    return {
      dates: [],
      date_menu: false,

      form: {},
      dialog: false,
      isLoading: false,
      employees: [],
      schedulers: [],
      sites: [],

      msg: "",
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`/monthly_salaries/masters`);
      masters = masters.data;
      masters.sites.forEach((site) => {
        this.sites.push({
          id: site.id,
          text: site.name,
          value: site.id,
        });
      });
      let schedulers = await axios.get(`/schedulers`);
      schedulers = schedulers.data.data;
      schedulers.forEach((scheduler) => {
        this.schedulers.push({
          id: scheduler.id,
          text: scheduler.name,
          value: scheduler.id,
        });
      });
    },
    async getSiteWiseEmployees() {
      this.employees = [];
      let employees = await axios.get(`/users?site_id=${this.form.site_id}`);
      employees.data.data.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.full_name,
          value: employee.id,
        });
      });

      // console.log(this.groups);
    },
    // async copy() {
    //   console.log(this.form.to_employee);
    //   try {
    //     this.isLoading = true;
    //     let query =
    //       "from_employee_id=" +
    //       this.selectedEmployee +
    //       "&from_month=" +
    //       this.form.selectedMonth +
    //       "&from_year=" +
    //       this.form.selectedYear +
    //       "&to_employee_ids=" +
    //       this.form.to_employee_ids;
    //     console.log(query);
    //     let copyDailyTasks = await axios.get(`/copy_daily_tasks?${query}`);
    //     this.msg = copyDailyTasks.data.message;
    //     this.dialog = true;

    //     this.isLoading = false;

    //     //   this.$router.push("/safety-videos");
    //   } catch (e) {p
    //     this.isLoading = false;
    //   }
    // },

    async save() {
      this.isLoading = true;
      let dates = this.dates.splice(",");
      let from_date = dates[0];
      let to_date = dates[1];
      this.form["from_date"] = from_date;
      this.form["to_date"] = to_date;

      let assign = await axios.post(`/scheduler_details/assign`, this.form);
      this.msg = assign.data.message;
      this.dialog = true;
      this.isLoading = false;
    },
  },
};
</script>
