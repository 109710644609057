<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <v-row class="fill-height pa-2" align="center" justify="center">
              <div class="text-h5">Employee of the Month</div>
            </v-row>
            <!-- Dashboard Container -->
            <v-container>
              <v-carousel
                v-model="model"
                style="height: 180px !important"
                hide-delimiters
              >
                <v-carousel-item v-for="(color, i) in colors" :key="color">
                  <v-sheet
                    class="fill-height"
                    :color="color"
                    height="100%"
                    tile
                    style="height: 180px !important"
                  >
                    <v-row align="center" justify="center">
                      <div class="text-h6 mt-4 mb-11">Month {{ i + 1 }}</div>
                    </v-row>
                    <v-row align="center" justify="center">
                      <div class="text-h5 mt-12">Emp Name {{ i + 1 }}</div>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
              <v-row class="ma-0">
                <v-col class="pa-0 text-h6">Latest News:</v-col>
                <!-- <v-col class="pa-0 pr-4 text-decoration-underline" align="right"
                  >View All</v-col
                > -->
              </v-row>

              <!-- Category Cards -->
              <template v-if="news_articles.length > 0">
                <v-row
                  class="ma-0"
                  v-for="news_article in news_articles"
                  :key="news_article.id"
                >
                  <v-col class="pa-0">
                    <!-- class="px-4 my-6" -->

                    <v-row class="ma-0">
                      <v-col class="pa-0"
                        >{{ news_article.category }}:
                        <hr
                      /></v-col>
                      <v-col
                        v-if="news_articles.length > 2"
                        class="pa-0 pr-4 text-decoration-underline"
                        align="right"
                        >View All</v-col
                      >
                    </v-row>
                    <v-row
                      class="ma-0"
                      v-for="news in news_article.values"
                      :key="news.id"
                    >
                      <v-col class="pa-0 mt-0">
                        <router-link :to="`/weekly-news-details/${news.id}`">
                          <v-card rounded="xl" class="my-2" elevation="10">
                            <v-row align="center" class="ma-0">
                              <v-col cols="3" class="pa-0">
                                <!-- :src="news.image" -->
                                <v-img
                                  :src="`${mediaUrl + news.image_path}`"
                                  alt="News"
                                  width="auto"
                                  height="auto"
                                ></v-img>
                              </v-col>

                              <v-col>
                                <span
                                  class="text-subtitle-1 font-weight-medium"
                                  style="text-decoration: none !important"
                                >
                                  {{ news.title }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-card>
                        </router-link>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row>
                  <v-col align="center" style="min-height: 100px">
                    <v-progress-circular
                      v-if="isLoading == true"
                      :size="70"
                      :width="7"
                      color="blue"
                      indeterminate
                    ></v-progress-circular>
                    <span v-else> No News Article Found. </span>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      news_articles: [],

      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
      model: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let news_articles = await axios.get(
        `/news_articles?fetch_category_wise=true`
      );
      this.news_articles = news_articles.data.category_wise_articles;
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.custom-border {
  border-color: #ff0000; /* Replace with your desired border color */
}
</style>
