import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: colors.indigo.darken1, // #E53935
        primary: colors.blue.lighten1, // #E53935
        secondary: colors.deepOrange.lighten4, // #E53935
        // background: "#75A2C3",
        background: colors.blue.lighten5,
      },
      options: {
        customProperties: true,
      },
    },
  },
});
