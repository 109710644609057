<template>
  <v-main>
    <v-container fluid>
      <v-card class="pa-4" rounded="xl" color="blue lighten-5" width="auto">
        <v-row justify="space-between" align="center">
          <v-col cols="6">
            <span>Employee Identity</span>
          </v-col>
        </v-row>

        <v-divider class="my-4"></v-divider>

        <!-- Add New Employee Identity Form -->
        <v-row class="mb-4">
          <v-col cols="12" sm="6">
            <v-select
              solo
              hide-details=""
              dense
              rounded
              v-model="form.document_type_id"
              :items="documentTypes"
              label="Document Types"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.name_in_document"
              placeholder="Name in document"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.document_no"
              placeholder="No in document"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <input
              type="file"
              id="file"
              name="file"
              ref="file"
              accept="image/*"
            />
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="form.is_document_verified"
              label="Is Document Verified"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col align="center" class="pa-0">
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,

      form: {
        document_type_id: "",
      },
      documentTypes: [],
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;
      masters.document_types.forEach((documentType) => {
        this.documentTypes.push({
          id: documentType.id,
          text: documentType.code,
          value: documentType.id,
        });
      });
    },

    async save() {
      try {
        this.isLoading = true;
        if (this.roleName != "Admin") {
          this.form.role_id = 3;
        }
        // console.log(this.$route.params.userId);

        await axios.post(
          `/users/${this.$route.params.userId}/user_identities`,
          this.form
        );
        this.handleFileUploadCreate();
        // this.isLoading = false;
        // this.$router.push("/employees");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async handleFileUploadCreate() {
      console.log("hi");

      let attachment = this.$refs.file?.files[0];
      let formData = new FormData();

      formData.append("imagepath", attachment);
      formData.append("user_id", this.$route.params.userId);
      formData.append("document_type_id", this.form.document_type_id);
      await axios
        .post("upload_document_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.$router.go(this.$router.currentRoute);
    },
  },
};
</script>
