<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-6" rounded="xl">
        <v-row class="mx-4 my-2">
          <v-col class="pa-0 mt-2">
            <h5 class="text-h6" align="left">Name*</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.name"
              placeholder="Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Site</h5>
            <v-select
              solo
              dense
              rounded
              hide-details=""
              v-model="form.group_id"
              :items="groups"
              label="Site"
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-col class="pa-0">
            <v-simple-table fixed-header height="200px" class="mx-4 my-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 45px">Sr</th>
                    <th class="text-left" style="min-width: 140px">Name</th>
                    <th class="text-left" style="min-width: 140px">Per Rate</th>
                    <th class="text-left" style="min-width: 140px">Qty</th>
                    <th class="text-left" style="min-width: 140px">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      material_request_detail, i
                    ) in material_request_details"
                    :key="`name${i}`"
                  >
                    <td>
                      <v-btn
                        x-small
                        color="primary"
                        icon
                        @click="
                          deleteMaterialRequestDetails(material_request_detail)
                        "
                      >
                        {{ i + 1 }}<v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-col class="px-0">
                        <v-text-field
                          v-model="material_request_detail.name"
                          outlined
                          dense
                          label="Name"
                          hide-details="auto"
                          :error-messages="
                            material_request_detail.name == ''
                              ? 'this name field is required'
                              : ''
                          "
                        ></v-text-field>
                      </v-col>
                    </td>
                    <td>
                      <v-col class="px-0">
                        <v-text-field
                          v-model="material_request_detail.per_unit_price"
                          outlined
                          dense
                          label="Per Unit"
                          hide-details="auto"
                          :error-messages="
                            material_request_detail.per_unit_price == ''
                              ? 'this per price field is required'
                              : ''
                          "
                        ></v-text-field>
                      </v-col>
                    </td>
                    <td>
                      <v-col class="px-0">
                        <v-text-field
                          v-model="material_request_detail.qty"
                          outlined
                          dense
                          label="Qty"
                          hide-details="auto"
                          :error-messages="
                            material_request_detail.qty == ''
                              ? 'this qty field is required'
                              : ''
                          "
                          @change="total(material_request_detail)"
                        ></v-text-field>
                      </v-col>
                    </td>
                    <td>
                      <v-col class="px-0">
                        <v-text-field
                          v-model="material_request_detail.total"
                          outlined
                          dense
                          label="Total"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" align="center">
                      <v-btn
                        color="blue"
                        dark
                        small
                        @click="addEmptyMaterialRequestDetails()"
                        >Add Row</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-6 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {},
      material_request_details: [],
      groups: [],
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("material_requests/masters");
      masters = masters.data;
      masters.groups.forEach((group) => {
        this.groups.push({
          id: group.id,
          text: group.name,
          value: group.id,
        });
      });
    },
    async save() {
      if (this.material_request_details.length > 0) {
        this.isSaving = true;
        let totalqty = 0;
        let grandTotal = 0;
        this.material_request_details.forEach((materialRequest) => {
          totalqty += parseInt(materialRequest.qty);
          grandTotal += parseInt(materialRequest.total);
        });

        let payload = {
          site_id: this.site.id,
          name: this.form.name,
          group_id: this.form.group_id,
          qty: totalqty,
          total: grandTotal,
          material_request_details: this.material_request_details,
        };

        let response = await axios.post(`/material_requests`, payload);
        this.material_request_details = response.data.data;
        this.$router.push("/material-requests");
        this.isSaving = false;
      }
    },
    addEmptyMaterialRequestDetails() {
      this.material_request_details.push({
        name: "",
        per_unit_price: "0",
        qty: "0",
        total: "0",
      });
    },
    deleteMaterialRequestDetails(material_request_detail) {
      this.material_request_details = this.material_request_details.filter(
        (ar) => ar != material_request_detail
      );
    },
    total(material_request_detail) {
      let total = 0;
      total =
        material_request_detail.per_unit_price * material_request_detail.qty;
      material_request_detail.total = total;
    },
  },
};
</script>
