<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-4" rounded readonly="xl">
        <v-row class="mx-4 mt-2">
          <v-col class="pa-0 mb-2">
            <h5 class="text-h6" align="left">Date of Application</h5>
            <v-col class="pa-0 pt-4">
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                readonly
                v-model="form.date_of_application"
                placeholder="Date of Application"
                outlined
              ></v-text-field>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Name of Employee</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.employee_name"
              placeholder="Employee Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Designation</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.designation"
              placeholder="Designation"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Client Name</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.client_name"
              placeholder="Client Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Location</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.location"
              placeholder="Location"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">
              Request Type: {{ form.request_type }}
            </h5>
          </v-col>
        </v-row>
        <!-- <v-row class="mx-4 mt-2 my-2 pb-4"> -->
        <template v-if="form.request_type == 'Uniform'">
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Shirt Size</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                readonly
                v-model="form.shirt_size"
                placeholder="Shirt Size"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Pant Size</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                readonly
                v-model="form.pant_size"
                placeholder="Pant Size"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Jacket Size</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                readonly
                v-model="form.jacket_size"
                placeholder="Jacket Size"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-if="form.request_type == 'Id Card'">
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Temp Card</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                readonly
                v-model="form.temp_card"
                placeholder="Temp Card"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Permanent Card</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                readonly
                v-model="form.permanent_card"
                placeholder="Permanent Card"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-if="form.request_type == 'Shoe'">
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Size</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                readonly
                v-model="form.shoe_size"
                placeholder="Shoe"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </template>

        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Reporting Supervisor Name:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.reporting_supervisor_name"
              placeholder="Reporting Supervisor Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Is Approved:</h5>
            <v-switch
              color="orange"
              v-model="form.is_approved"
              align="center"
              :readonly="roleName == 'Employee'"
            ></v-switch>
          </v-col>
        </v-row>

        <hr class="mx-5" />
        <v-row class="mx-4 mt-2 my-2" v-if="form.approved_by != null">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Approved By:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.approved_by.full_name"
              placeholder="Approved By"
              outlined
            ></v-text-field>
            <!-- v-model="form.supervisor.full_name" -->
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2" v-if="form.approved_date != null">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Approved Date:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.approved_date"
              placeholder="Approved Date"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2 pb-6" v-if="form.issue_date != null">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Issue Date:</h5>
            <v-col class="pa-0 pt-4">
              <v-dialog
                ref="ApplicationDae"
                :close-on-content-click="false"
                v-model="issue_date_menu"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.issue_date"
                    label="Issue Date"
                    solo
                    dense
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.issue_date"
                  scrollable
                  @input="issue_date_menu = false"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-6 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      issue_date_menu: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      form: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(
        `/requisition_requests/${this.$route.params.requisitionId}`
      );
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.approved_by_id = this.user.id;
        this.form.approved_date = moment().format("YYYY-MM-DD");
        let requisition = await axios.patch(
          `/requisition_requests/${this.form.id}`,
          this.form
        );
        this.requisition = requisition.data;
        this.isLoading = false;
        this.$router.push("/request-forms");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>
