<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-12" rounded="xl">
        <v-row justify="center" class="mt-4" style="max-height: 80px">
          <v-col md="8" lg="6" sm="6" class="px-0 pb-0 mb-0">
            <v-list-item style="bottom: 65px">
              <v-list-item-content>
                <v-list-item-title class="mt-10"
                  ><v-img
                    :src="require('@/assets/voe_imgs/setting.png')"
                    max-height="30"
                    max-width="30"
                  ></v-img
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar size="100">
                <img
                  :src="require('@/assets/voe_imgs/logo.png')"
                  alt="Profile"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="mt-10"
                  ><v-img
                    :src="require('@/assets/voe_imgs/camera-icon.png')"
                    max-height="30"
                    max-width="30"
                  ></v-img
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row md="8" lg="6" sm="6" class="ma-0">
          <v-col class="pa-0 blue--text font-weight-bold">
            {{ user.full_name }}
          </v-col>
        </v-row>
        <v-row md="8" lg="6" sm="6" class="ma-0">
          <v-col class="pa-0 caption"> Emp Code: {{ user.employee_id }} </v-col>
        </v-row>

        <v-row class="mx-4 mt-5 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Email</h5>
            <v-text-field
              append-icon="mdi-pencil"
              hide-details=""
              solo
              dense
              rounded
              v-model="form.email"
              placeholder="Email"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Mobile Number</h5>
            <v-text-field
              hide-details=""
              append-icon="mdi-pencil"
              solo
              dense
              rounded
              v-model="form.mobile"
              placeholder="Mobile"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Designation</h5>
            <v-text-field
              hide-details=""
              append-icon="mdi-pencil"
              solo
              readonly
              dense
              rounded
              v-model="designation"
              placeholder="Designation"
              outlined
              class="blue--text"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col align="center">
            <v-btn
              @click="update"
              min-width="200"
              :loading="isLoading"
              color="blue"
              rounded
              max-width="100"
              dark
              >Save Details</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {},
      designation: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/users/${this.user.id}`);
      this.form = form.data.data;
      const findDesignation = this.form.user_categories.find(
        (item) => item.category.category_name == "DESIGNATION"
      );

      this.designation = findDesignation
        ? findDesignation.category_list.name
        : "";
      this.isLoading = false;
    },
    async update() {
      try {
        this.isLoading = true;
        await axios.patch(`/users/${this.user.id}`, this.form);
        this.isLoading = false;
        this.$router.push("/profile");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
