<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0">
          <!-- Main Container -->
          <v-card class="mt-4" rounded="xl" color="blue lighten-5" width="auto">
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="pt-4">
                <v-col>
                  <v-toolbar-title class="text-h4 blue--text">
                    My Dashboard
                  </v-toolbar-title>
                  <h4>Select a category</h4>
                </v-col>
              </v-row>

              <!-- Category Cards -->
              <v-row class="pb-5 mx-1">
                <template v-if="user.roles[0].name == 'Employee'">
                  <v-col
                    v-for="category in employeeMenu"
                    :key="category.title"
                    class="pa-0 px-1 pb-3"
                  >
                    <v-card
                      min-height="135"
                      min-width="96"
                      rounded="lg"
                      elevation="10"
                      class="pa-0 ma-0"
                      @click="navigateTo(category.route)"
                    >
                      <v-card-text align="center">
                        <v-img
                          :src="category.icon"
                          alt="Category Icon"
                          width="50"
                          height="50"
                          class="mt-2"
                        ></v-img>
                        <h5 class="pa-0">{{ category.title }}</h5>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
                <template v-if="user.roles[0].name == 'Supervisor'">
                  <v-col
                    v-for="category in supervisorMenu"
                    :key="category.title"
                    class="pa-0 px-1 pb-3"
                  >
                    <v-card
                      min-height="135"
                      min-width="96"
                      rounded="lg"
                      elevation="10"
                      class="pa-0 ma-0"
                      @click="navigateTo(category.route)"
                    >
                      <v-card-text align="center">
                        <v-img
                          :src="category.icon"
                          alt="Category Icon"
                          width="50"
                          height="50"
                          class="mt-2"
                        ></v-img>
                        <h5 class="pa-0">{{ category.title }}</h5>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
                <template v-if="user.roles[0].name == 'Client'">
                  <v-col
                    v-for="category in clientMenu"
                    :key="category.title"
                    class="pa-0 px-1 pb-3"
                  >
                    <v-card
                      min-height="135"
                      min-width="96"
                      rounded="lg"
                      elevation="10"
                      class="pa-0 ma-0"
                      @click="navigateTo(category.route)"
                    >
                      <v-card-text align="center">
                        <v-img
                          :src="category.icon"
                          alt="Category Icon"
                          width="50"
                          height="50"
                          class="mt-2"
                        ></v-img>
                        <h5 class="pa-0">{{ category.title }}</h5>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
                <template v-if="user.roles[0].name == 'Admin'">
                  <v-col
                    v-for="category in adminMenu"
                    :key="category.title"
                    class="pa-0 px-1 pb-3"
                  >
                    <v-card
                      min-height="135"
                      min-width="96"
                      rounded="lg"
                      elevation="10"
                      class="pa-0 ma-0"
                      @click="navigateTo(category.route)"
                    >
                      <v-card-text align="center">
                        <v-img
                          :src="category.icon"
                          alt="Category Icon"
                          width="50"
                          height="50"
                          class="mt-2"
                        ></v-img>
                        <h5 class="pa-0">{{ category.title }}</h5>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </v-container>

            <!-- Footer Text -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      employeeMenu: [
        {
          title: "Attendance",
          icon: require("@/assets/voe_imgs/attendance.png"),
          route: "/attendances",
        },
        {
          title: "Daily Work",
          icon: require("@/assets/voe_imgs/list.png"),
          route: "/daily-works",
        },
        // {
        //   title: "Salary Slips",
        //   icon: require("@/assets/voe_imgs/salary-slip.png"),
        //   route: "/salary-slips",
        // },
        {
          title: "Training Videos",
          icon: require("@/assets/voe_imgs/training-video.png"),
          route: "/training-videos",
        },
        {
          title: "Request Form",
          icon: require("@/assets/voe_imgs/request-form.png"),
          route: "/request-forms",
        },
        // {
        //   title: "Weekly News",
        //   icon: require("@/assets/voe_imgs/weekly-news.png"),
        //   route: "/weekly-news",
        // },
        // {
        //   title: "My profile",
        //   icon: require("@/assets/voe_imgs/profile.png"),
        //   route: "/profile",
        // },
        {
          title: "Referral",
          icon: require("@/assets/voe_imgs/referral.png"),
          route: "/referral-forms",
        },

        // {
        //   title: "Company Vision",
        //   icon: require("@/assets/voe_imgs/company-vision.png"),
        //   route: "/company-vision",
        // },
        // {
        //   title: "Safety & Emergency",
        //   icon: require("@/assets/voe_imgs/safety-emergency.png"),
        //   route: "/safety-emergency",
        // },
        // {
        //   title: "Code of Conduct",
        //   icon: require("@/assets/voe_imgs/code-of-conduct.png"),
        //   route: "/company-code-of-conduct",
        // },
      ],
      supervisorMenu: [
        {
          title: "Attendance",
          icon: require("@/assets/voe_imgs/attendance.png"),
          route: "/attendances",
        },
        {
          title: "Daily Work",
          icon: require("@/assets/voe_imgs/list.png"),
          route: "/daily-works",
        },
        {
          title: "Schedulers",
          icon: require("@/assets/voe_imgs/list.png"),
          route: "/schedulers",
        },
        {
          title: "Assign Scheduler",
          icon: require("@/assets/voe_imgs/list.png"),
          route: "/schedulers/assign",
        },
        // {
        //   title: "Copy Daily Work",
        //   icon: require("@/assets/voe_imgs/list.png"),
        //   route: "/daily-works/copy",
        // },

        {
          title: "Visit Report",
          icon: require("@/assets/voe_imgs/visit-report.png"),
          route: "/visiting-reports",
        },
        {
          title: "Training Videos",
          icon: require("@/assets/voe_imgs/training-video.png"),
          route: "/training-videos",
        },
        {
          title: "Request Form",
          icon: require("@/assets/voe_imgs/request-form.png"),
          route: "/request-forms",
        },
        // {
        //   title: "Weekly News",
        //   icon: require("@/assets/voe_imgs/weekly-news.png"),
        //   route: "/weekly-news",
        // },

        {
          title: "Referral",
          icon: require("@/assets/voe_imgs/referral.png"),
          route: "/referral-forms",
        },

        // {
        //   title: "Company Vision",
        //   icon: require("@/assets/voe_imgs/company-vision.png"),
        //   route: "/company-vision",
        // },
        // {
        //   title: "Safety & Emergency",
        //   icon: require("@/assets/voe_imgs/safety-emergency.png"),
        //   route: "/safety-emergency",
        // },
        // {
        //   title: "Code of Conduct",
        //   icon: require("@/assets/voe_imgs/code-of-conduct.png"),
        //   route: "/company-code-of-conduct",
        // },
        // {
        //   title: "Supervisor Tasks",
        //   icon: require("@/assets/voe_imgs/supervisor-task.png"),
        //   route: "/client-forms",
        // },
        // {
        //   title: "Material Requests",
        //   icon: require("@/assets/voe_imgs/material-request.png"),
        //   route: "/material-requests",
        // },
        {
          title: "New Employees",
          icon: require("@/assets/voe_imgs/new-joinee.png"),
          route: "/employees",
        },
        {
          title: "Employee Details",
          icon: require("@/assets/voe_imgs/employees.png"),
          route: "/new-joinees",
        },
        // {
        //   title: "Letters",
        //   icon: require("@/assets/voe_imgs/letters.png"),
        //   route: "/letters",
        // },
        // {
        //   title: "Expenses",
        //   icon: require("@/assets/voe_imgs/expenses.png"),
        //   route: "/letters",
        // },
      ],
      clientMenu: [
        {
          title: "Attendance",
          icon: require("@/assets/voe_imgs/attendance.png"),
          route: "/attendances",
        },
        {
          title: "Daily Work",
          icon: require("@/assets/voe_imgs/list.png"),
          route: "/daily-works",
        },
        {
          title: "Visit Report",
          icon: require("@/assets/voe_imgs/salary-slip.png"),
          route: "/client-visit-forms",
        },

        {
          title: "Request Form",
          icon: require("@/assets/voe_imgs/request-form.png"),
          route: "/request-forms",
        },
        // {
        //   title: "Weekly News",
        //   icon: require("@/assets/voe_imgs/weekly-news.png"),
        //   route: "/news-articles",
        // },
        {
          title: "New Joinee",
          icon: require("@/assets/voe_imgs/employees.png"),
          route: "/new-joinees",
        },
        // {
        //   title: "Letters",
        //   icon: require("@/assets/voe_imgs/letters.png"),
        //   route: "/letters",
        // },

        {
          title: "Billing",
          icon: require("@/assets/voe_imgs/billing-history.png"),
          route: "/billings",
        },
        {
          title: "Agreement & Compliances",
          icon: require("@/assets/voe_imgs/slip.png"),
          route: "/agreement-complaiances",
        },
        // {
        //   title: "Material Requests",
        //   icon: require("@/assets/voe_imgs/material-request.png"),
        //   route: "/client-material-requests",
        // },
      ],
      adminMenu: [
        {
          title: "Daily Work",
          icon: require("@/assets/voe_imgs/list.png"),
          route: "/daily-works",
        },
        {
          title: "Training Videos",
          icon: require("@/assets/voe_imgs/training-video.png"),
          route: "/training-video-lists",
        },
        {
          title: "Request Form",
          icon: require("@/assets/voe_imgs/request-form.png"),
          route: "/request-forms",
        },
        {
          title: "Employees",
          icon: require("@/assets/voe_imgs/employees.png"),
          route: "/employees",
        },
        {
          title: "Safety & Emergency",
          icon: require("@/assets/voe_imgs/safety-emergency.png"),
          route: "/safety-videos",
        },
        {
          title: "Permissions",
          icon: require("@/assets/voe_imgs/list.png"),
          route: "/permissions",
        },
      ],
    };
  },
  // mounted() {
  //   console.log("page called");
  // },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
  },
};
</script>
