<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-12" rounded="xl">
        <v-row class="mx-4 mt-5 my-2">
          <v-col class="pa-0 mt-4">
            <h5 class="text-h6" align="left">Category</h5>
            <v-select
              solo
              hide-details=""
              dense
              rounded
              v-model="form.category_id"
              :items="categories"
              label="Categories"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Title</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.title"
              placeholder="Title"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Description</h5>
            <v-textarea
              outlined
              name="input-7-4"
              label="Description"
              v-model="form.description"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Edited by</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.edited_by"
              placeholder="Edited by"
              outlined
              class="blue--text"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Image</h5>
            <input type="file" ref="imagePath" style="min-width: 140px" />
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col align="center">
            <v-btn
              @click="save"
              min-width="200"
              :loading="isLoading"
              color="blue"
              rounded
              max-width="100"
              dark
              >Save</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "NewsArticle",
  data() {
    return {
      form: {},
      categories: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.form.site_id = this.site.id;
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("news_articles/masters");
      masters = masters.data;
      masters.news_categories.forEach((news_category) => {
        this.categories.push({
          id: news_category.id,
          text: news_category.description,
          value: news_category.id,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        let news_article = await axios.post("/news_articles", this.form);
        this.news_article = news_article.data.data;
        this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/news-articles");
      } catch (e) {
        this.isLoading = false;
      }
    },

    async handleFileUpload() {
      this.isLoading = true;

      this.imagePath = this.$refs.imagePath.files[0];
      let formData = new FormData();
      formData.append("id", this.news_article.id);
      formData.append("image_path", this.imagePath);
      await axios
        .post("upload_news_article_image_path", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.feedback = response.data.data;
          this.isLoading = false;
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.sticky-column {
  position: sticky;
  left: 0;
  background-color: white; /* Match background color of table header */
  z-index: 1; /* Ensure the fixed column appears above other columns */
}
.sticky-header {
  position: sticky;
  left: 0;
  background-color: white; /* Match background color of table header */
  z-index: 3 !important; /* Ensure the fixed column appears above other columns */
}
</style>
