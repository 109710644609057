<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="mx-4 mt-2">
          <v-col class="pa-0 mt-2">
            <h5 class="text-h6" align="left">Date</h5>
            <v-col class="pa-0 pt-4">
              <v-dialog
                ref="ApplicationDae"
                :close-on-content-click="false"
                v-model="date_of_application_menu"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date"
                    label="Date"
                    readonly
                    solo
                    dense
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date"
                  scrollable
                  @input="date_of_application_menu = false"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Request Type</h5>
            <v-select
              solo
              hide-details=""
              dense
              rounded
              v-model="form.type"
              :items="requestTypes"
              label="Request Type"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Description</h5>
            <v-textarea
              clearable
              label="Description"
              solo
              dense
              rounded
              v-model="form.description"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-6 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <!-- :loading="isLoading" -->
            <!-- @click="login" -->
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      form: {},
      requestTypes: [
        { id: "Request", value: "Request", text: "Request" },
        { id: "Complaint", value: "Complaint", text: "Complaint" },
        { id: "Feedback", value: "Feedback", text: "Feedback" },
        { id: "Suggestion", value: "Suggestion", text: "Suggestion" },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(
        `/other_requests/${this.$route.params.otherReqId}`
      );
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.site_id = this.site.id;
        let other_request = await axios.patch(
          `/other_requests/${this.form.id}`,
          this.form
        );
        this.other_request = other_request.data;
        this.isLoading = false;
        this.$router.push("/other-requests");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>
