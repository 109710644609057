<template>
  <v-btn v-if="link" class="mx-2 mb-2" fab x-small color="primary" :to="link">
    <v-icon dark> mdi-arrow-left </v-icon>
  </v-btn>
  <v-btn
    v-else
    fab
    x-small
    class="mx-2 mb-2"
    color="primary"
    @click="$router.back()"
  >
    <v-icon dark> mdi-arrow-left </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: ["link"],
};
</script>

<style></style>
