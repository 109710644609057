<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            v-if="roleName == 'Employee' || roleName == 'Supervisor'"
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="ma-0">
                <v-col class="pa-0" style="min-width: 250px"
                  ><p class="py-3 text-subtitle-1">
                    Apply New {{ $route.query.type }} Request:
                  </p></v-col
                >
                <v-col class="pa-0">
                  <router-link
                    :to="`/requisition-create?type=${$route.query.type}`"
                  >
                    <v-btn color="white" dark fab>
                      <v-icon color="blue lighten1">mdi-plus</v-icon>
                    </v-btn>
                  </router-link>
                </v-col>
              </v-row>

              <!-- Category Cards -->
              <template v-if="requisitions.length > 0">
                <v-card
                  rounded="xl"
                  class="custom-border px-4 my-6"
                  elevation="10"
                  v-for="requisition in requisitions"
                  :key="requisition.id"
                >
                  <!-- :to="leave" -->
                  <router-link
                    :to="`/requisitions/${requisition.id}`"
                    style="text-decoration: none"
                  >
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Request Date:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ requisition.date_of_application }}
                        </span>
                      </v-col>

                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Issue Date:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ requisition.issue_date }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Is Approved:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ requisition.is_approved == 1 ? "Yes" : "No" }}
                        </span>
                      </v-col>

                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Approved Date:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ requisition.approved_date }}
                        </span>
                      </v-col>
                    </v-row>
                  </router-link>
                </v-card>
              </template>
              <template v-else>
                <v-row justify="center" class="pb-6">
                  <v-col align="center">
                    <span> No {{ $route.query.type }} Request Found.</span>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card>
          <v-card
            v-if="roleName == 'Admin' || roleName == 'Supervisor'"
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="ma-0">
                <v-col class="pa-0" style="min-width: 250px"
                  ><p class="py-3 text-subtitle-1">
                    {{ $route.query.type }} Applied by
                    {{ roleName == "Admin" ? "Supervisors" : "Employees" }}:
                  </p></v-col
                >
              </v-row>

              <!-- Category Cards -->
              <template v-if="employees_requisitions.length != 0">
                <v-card
                  rounded="xl"
                  class="custom-border px-4 my-6"
                  elevation="10"
                  v-for="employee_requisition in employees_requisitions"
                  :key="employee_requisition.id"
                >
                  <!-- :to="leave" -->
                  <v-container>
                    <router-link
                      :to="`/requisitions/${employee_requisition.id}`"
                      style="text-decoration: none"
                    >
                      <v-row align="center" class="ma-0">
                        <v-col class="pa-0">
                          <span class="text-subtitle-1 font-weight-medium">
                            {{
                              roleName == "Admin" ? "Supervisors" : "Employees"
                            }}
                            Name:
                          </span>
                          <br />
                          <span
                            class="text-subtitle-1 font-weight-medium black--text"
                          >
                            {{ employee_requisition.user.full_name }}
                          </span>
                        </v-col>
                        <v-col class="pa-0">
                          <span class="text-subtitle-1 font-weight-medium">
                            Employee Code:
                          </span>
                          <br />
                          <span
                            class="text-subtitle-1 font-weight-medium black--text"
                          >
                            {{ employee_requisition.user.employee_id }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row align="center" class="ma-0">
                        <v-col class="pa-0">
                          <span class="text-subtitle-1 font-weight-medium">
                            Request Date:
                          </span>
                          <br />
                          <span
                            class="text-subtitle-1 font-weight-medium black--text"
                          >
                            {{ employee_requisition.date_of_application }}
                          </span>
                        </v-col>

                        <v-col class="pa-0">
                          <span class="text-subtitle-1 font-weight-medium">
                            Issue Date:
                          </span>
                          <br />
                          <span
                            class="text-subtitle-1 font-weight-medium black--text"
                          >
                            {{ employee_requisition.issue_date }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row align="center" class="ma-0">
                        <v-col class="pa-0">
                          <span class="text-subtitle-1 font-weight-medium">
                            Is Approved:
                          </span>
                          <br />
                          <span
                            class="text-subtitle-1 font-weight-medium black--text"
                          >
                            {{
                              employee_requisition.is_approved == 1
                                ? "Yes"
                                : "No"
                            }}
                          </span>
                        </v-col>

                        <v-col class="pa-0">
                          <span class="text-subtitle-1 font-weight-medium">
                            Approved Date:
                          </span>
                          <br />
                          <span
                            class="text-subtitle-1 font-weight-medium black--text"
                          >
                            {{ employee_requisition.approved_date }}
                          </span>
                        </v-col>
                      </v-row>
                    </router-link>
                  </v-container>
                </v-card>
              </template>
              <template v-else>
                <v-row justify="center" class="pb-6">
                  <v-col align="center">
                    <span> No {{ $route.query.type }} Request Found.</span>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      requisitions: [],
      employees_requisitions: [],
    };
  },
  mounted() {
    if (this.roleName == "Admin") {
      this.getSupervisorData();
    } else {
      this.getData();
    }
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let requisitions = await axios.get(
        `requisition_requests?type=${this.$route.query.type}`
      );
      this.requisitions = requisitions.data.data;
      this.isLoading = false;
      this.getEmployeeData();
    },
    async getEmployeeData() {
      this.isLoading = true;
      let employees_requisitions = await axios.get(
        `requisition_requests?fetch_employees_leave=true&type=${this.$route.query.type}`
      );
      this.employees_requisitions = employees_requisitions.data.data;
      console.log(this.employees_requisitions);
      this.isLoading = false;
    },
    async getSupervisorData() {
      this.isLoading = true;
      let employees_requisitions = await axios.get(
        `requisition_requests?fetch_supervisors_leave=true&type=${this.$route.query.type}`
      );
      this.employees_requisitions = employees_requisitions.data.data;
      this.isLoading = false;
    },
  },
};
</script>
