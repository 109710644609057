<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="mx-4 mt-2">
          <v-col class="pa-0 mt-2">
            <h5 class="text-h6" align="left">Date of Application</h5>
            <v-col class="pa-0 pt-4">
              <v-dialog
                ref="ApplicationDae"
                :close-on-content-click="false"
                v-model="date_of_application_menu"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date_of_application"
                    label="Date of Application"
                    solo
                    dense
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date_of_application"
                  scrollable
                  @input="date_of_application_menu = false"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Name of Employee</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.employee_name"
              placeholder="Employee Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Designation</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.designation"
              placeholder="Designation"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Client Name</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.client_name"
              placeholder="Client Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Location</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.location"
              placeholder="Location"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">
              Request Type: {{ $route.query.type }}
            </h5>
          </v-col>
        </v-row>
        <!-- <v-row class="mx-4 mt-2 my-2 pb-4"> -->
        <template v-if="$route.query.type == 'Uniform'">
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Shirt Size</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                v-model="form.shirt_size"
                placeholder="Shirt Size"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Pant Size</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                v-model="form.pant_size"
                placeholder="Pant Size"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Jacket Size</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                v-model="form.jacket_size"
                placeholder="Jacket Size"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-if="$route.query.type == 'Id Card'">
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Temp Card</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                v-model="form.temp_card"
                placeholder="Temp Card"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Permanent Card</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                v-model="form.permanent_card"
                placeholder="Permanent Card"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-if="$route.query.type == 'Shoe'">
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Size</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                v-model="form.shoe_size"
                placeholder="Shoe"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </template>

        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Reporting Supervisor Name:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.reporting_supervisor_name"
              placeholder="Reporting Supervisor Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- <hr class="mx-5" /> -->
        <!-- <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Approved By:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              
              rounded
              v-model="form.supervisor.full_name"
              placeholder="Supervisor Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Approved Date:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              
              rounded
              v-model="form.supervisor.full_name"
              placeholder="Supervisor Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row> -->
        <!-- <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Signature of Employee:</h5>
            <input
              class="mt-4"
              type="file"
              name="signature_path"
              ref="signature_path"
              id=""
            />
          </v-col>
        </v-row> -->
        <v-row class="mx-4 mt-6 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <!-- :loading="isLoading" -->
            <!-- @click="login" -->
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      date_of_application_menu: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      form: {
        date_of_application: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
      },
    };
  },

  methods: {
    async save() {
      try {
        this.isLoading = true;
        this.form.user_id = this.user.id;
        this.form.request_type = this.$route.query.type;
        console.log(this.form);
        let requisition = await axios.post("/requisition_requests", this.form);
        this.requisition = requisition.data;
        // this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/request-forms");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    // async handleFileUpload() {
    //   this.isLoading = true;
    //   let attachment = this.$refs.file?.files[0];
    //   const requisitionId = this.requisition.id;
    //   let formData = new FormData();
    //   formData.append("id", requisitionId);
    //   formData.append("signature_path", attachment);
    //   await axios
    //     .post("upload_requisition_request_image", formData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     })
    //     .catch(function () {
    //       console.log("FAILURE!!");
    //     });
    //   if (this.leave) {
    //     this.success = true;
    //     this.form = [];
    //   }
    //   this.isLoading = false;
    //   // if (this.career_request) {
    //   //   this.success = true;
    //   //   this.form = [];
    //   // }
    //   // this.getData();
    // },
  },
};
</script>
