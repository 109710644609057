<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col>
          <backbutton></backbutton>
          <span
            class="text-h5 font-weight-bold"
            v-text="' List of Values'"
          ></span>
          <v-btn
            class="mx-2 mb-2"
            :loading="isSaving"
            :disabled="isSaving"
            small
            color="primary"
            @click="save"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            @change="getData"
            ref="value"
            v-model="value_id"
            :items="valueItems"
            label="Select Value"
            prepend-icon="mdi-format-list-text"
            solo
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12" v-if="value_id">
          <v-card elevation="6">
            <v-simple-table
              fixed-header
              :height="`${valueLists.length ? '370px' : ''}`"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Description</th>
                    <th class="text-left">Code</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(valueList, i) in valueLists"
                    :key="`valueList${i}`"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>
                      <v-col class="px-0">
                        <v-text-field
                          v-model="valueList.description"
                          outlined
                          dense
                          label="Description"
                          hide-details="auto"
                          :error-messages="
                            valueList.description == ''
                              ? 'this description field is required'
                              : ''
                          "
                        ></v-text-field>
                      </v-col>
                    </td>
                    <td>
                      <v-col class="px-0">
                        <v-text-field
                          :error-messages="
                            valueList.code == ''
                              ? 'this code field is required'
                              : ''
                          "
                          v-model="valueList.code"
                          outlined
                          dense
                          label="Code"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </td>
                    <td class="text-right">
                      <div class="text-center">
                        <v-tooltip bottom v-if="!valueList.id">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="primary"
                              icon
                              @click="deleteValueList(valueList)"
                            >
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                        <v-tooltip bottom v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="primary"
                              icon
                              @click="delete_data(valueList.id)"
                            >
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="value_id">
                    <td colspan="9">
                      <v-btn
                        outlined
                        block
                        color="primary"
                        @click="addEmptyValueList()"
                      >
                        Add New Row
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import backbutton from '@/components/backbutton.vue';
export default {
  components: { backbutton },
  name: "ValueListsPage",
  data() {
    return {
      value_id: "",
      valueItems: [],
      valueLists: [],
      isLoading: false,
      isSaving: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("value_lists/masters");
      masters = masters.data;
      masters.values.forEach((value) => {
        this.valueItems.push({
          id: value.id,
          text: value.name,
          value: value.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let valueLists = await axios.get(`/values/${this.value_id}/value_lists`);
      this.valueLists = valueLists.data.data;
      this.count = valueLists.data.count;
      this.isLoading = false;
      // this.exportToExcel();
    },
    addEmptyValueList() {
      this.valueLists.push({
        value_id: this.value_id,
        site_id: this.site.id,
        description: "",
        code: "",
        is_active: 1,
      });
    },
    deleteValueList(valueList) {
      this.valueLists = this.valueLists.filter((ar) => ar != valueList);
    },
    async save() {
      if (this.valueLists.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.valueLists,
        };
        let response = await axios.post(
          `/values/${this.value_id}/value_lists_multiple`,
          payload
        );
        this.valueLists = response.data.data;
        this.isSaving = false;
      }
    },
    async delete_data(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.post(`delete?id=${id}&table_name=value_lists`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
