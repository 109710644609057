<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="ma-0">
                <v-col class="pa-0" style="min-width: 166px">
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-3">
                      <v-toolbar-title>Budget: </v-toolbar-title>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-select
                        solo
                        dense
                        rounded
                        hide-details=""
                        v-model="name"
                        :items="budgets"
                        label="Budget"
                        @change="getData()"
                      ></v-select>
                      <!-- @change="updateCalendar" -->
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pa-0" style="min-width: 166px">
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-4">
                      <v-toolbar-title>Site Name: </v-toolbar-title>
                    </v-col>
                  </v-row>

                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-select
                        solo
                        dense
                        rounded
                        hide-details=""
                        v-model="group_id"
                        :items="siteNames"
                        label="Site Name"
                        @change="getData()"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="ma-0">
                <v-col cols="12" class="pl-0 pb-2">
                  <v-card rounded="r-xl" min-width="340">
                    <v-simple-table height="200px">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-left blue--text"
                              style="min-width: 56px"
                            >
                              Sr. No
                            </th>
                            <th
                              class="text-left blue--text"
                              style="min-width: 65px"
                            >
                              Name
                            </th>
                            <th
                              class="text-left blue--text"
                              style="min-width: 60px"
                            >
                              Per Rate
                            </th>
                            <th
                              class="text-left blue--text"
                              style="min-width: 60px"
                            >
                              Qty
                            </th>
                            <th
                              class="text-left blue--text"
                              style="min-width: 60px"
                            >
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              budget, at
                            ) in single_material_request.material_request_details"
                            :key="`name${at}`"
                          >
                            <td>{{ at + 1 }}</td>
                            <td style="min-width: 140px">{{ budget.name }}</td>
                            <td>{{ budget.per_unit_price }}</td>
                            <td>{{ budget.qty }}</td>
                            <td style="min-width: 70px">
                              {{ budget.total }}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th
                              class="text-left blue--text"
                              colspan="3"
                              style="min-width: 80px"
                            >
                              Total
                            </th>
                            <th class="text-left blue--text">
                              {{ single_material_request.qty }}
                            </th>
                            <th
                              class="text-left blue--text"
                              style="min-width: 70px"
                            >
                              {{ single_material_request.total }}
                            </th>
                          </tr>
                        </tfoot>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="ma-0 mb-12">
                <v-col cols="12" class="pl-0 pb-2">
                  <v-card rounded="r-xl" min-width="340">
                    <v-simple-table height="200px">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left blue--text pr-0">Sr.No</th>
                            <th class="text-left blue--text pr-0">Name</th>
                            <th class="text-left blue--text pr-0">Month</th>
                            <th class="text-left blue--text">Yes/No</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(material_request, at) in material_requests"
                            :key="at.name"
                            @click="getMaterialRequestDetails(material_request)"
                          >
                            <td class="pr-0">{{ at + 1 }}</td>
                            <td class="pr-0">{{ material_request.name }}</td>
                            <td class="pr-0">
                              {{ material_request.created_at | mm }}
                            </td>
                            <td class="pa-0">
                              <v-row class="ma-0">
                                <v-btn
                                  class="pa-0"
                                  :color="
                                    material_request.status == 1
                                      ? 'green'
                                      : 'grey lighten-2'
                                  "
                                  x-small
                                  dark
                                ></v-btn>
                                <v-btn
                                  class="pa-0"
                                  :color="
                                    material_request.status == 0
                                      ? 'red'
                                      : 'grey lighten-2'
                                  "
                                  x-small
                                  dark
                                ></v-btn>
                              </v-row>
                            </td>
                            <!-- <td>{{ site.is_accepted }}</td> -->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-fab-transition>
                    <v-btn
                      color="blue lighten-5"
                      dark
                      to="/material-request-create"
                      absolute
                      bottom
                      right
                      fab
                      width="50"
                      height="50"
                    >
                      <v-icon color="blue">mdi-plus</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      budgets: [],
      siteNames: [],
      material_requests: [],
      single_material_request: [],
      name: "",
      group_id: "",
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      let groups = await axios.get("material_requests/masters");
      groups = groups.data;
      groups.groups.forEach((group) => {
        this.siteNames.push({
          id: group.id,
          text: group.name,
          value: group.id,
        });
      });
    },
    async getData() {
      this.name = this.name != "" ? this.name : "";
      this.group_id = this.group_id != "" ? this.group_id : "";
      let masters = await axios.get(
        `material_requests?name=${this.name}&group_id=${this.group_id}`
      );
      masters = masters.data;
      masters.data.forEach((budget) => {
        this.budgets.push({
          id: budget.id,
          text: budget.name,
          value: budget.id,
        });
      });
      this.material_requests = masters.data;
    },

    getMaterialRequestDetails(material_request) {
      this.single_material_request = material_request;
      // this.material_request_details.qty = material_request.qty;
      // this.material_request_details.total = material_request.total;
      // this.material_request_details = material_request.material_request_details;
      console.log(this.single_material_request);
    },
  },
};
</script>
