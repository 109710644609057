<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
            min-height="430"
          >
            <v-row class="ma-0">
              <v-col class="pa-0">
                <h2 class="mx-4 my-4" style="width: 250px">Employee Lists</h2>
              </v-col>
              <v-col class="pa-0 ma-2">
                <v-fab-transition>
                  <v-btn
                    to="/employees/create"
                    color="blue lighten-5"
                    dark
                    absolute
                    fab
                    width="50"
                    height="50"
                  >
                    <v-icon color="blue">mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
              </v-col>
            </v-row>
            <v-row class="mx-4 mx-2 my-4">
              <v-col class="pa-0">
                <h5 class="text-h6" align="left">Select Client</h5>
                <v-select
                  solo
                  hide-details=""
                  dense
                  rounded
                  v-model="form.site_id"
                  :items="sites"
                  label="Client"
                  @change="getData()"
                ></v-select>
              </v-col>
            </v-row>
            <template v-if="users.length > 0">
              <v-container style="height: 300px; overflow-y: auto">
                <v-row class="ma-0">
                  <v-col class="pa-0 mx-3 mb-6">
                    <v-expansion-panels>
                      <v-expansion-panel v-for="user in users" :key="user.id">
                        <v-expansion-panel-header>
                          <v-row class="ma-0">
                            <v-col cols="10" class="pa-0"
                              ><span>{{ user.full_name }}</span>
                            </v-col>
                            <v-col cols="2" class="pa-0" align="right"
                              ><v-fab-transition>
                                <v-btn
                                  :to="`/employees/${user.id}`"
                                  color="blue lighten-5"
                                  dark
                                  fab
                                  x-small
                                >
                                  <v-icon color="blue">mdi-pencil</v-icon>
                                </v-btn>
                              </v-fab-transition></v-col
                            >
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row class="ma-0 py-1">
                            <v-col class="pa-0" style="max-width: 100px">
                              <span>Email</span>
                            </v-col>
                            <v-col class="pa-0" align="left">
                              <span>: {{ user.email }}</span>
                            </v-col>
                          </v-row>
                          <v-row class="ma-0 py-1">
                            <v-col class="pa-0" style="max-width: 100px">
                              <span>Emp Code</span>
                            </v-col>
                            <v-col class="pa-0" align="left">
                              <span
                                >:
                                {{
                                  user.employee_id ? user.employee_id : ""
                                }}</span
                              >
                            </v-col>
                          </v-row>
                          <v-row class="ma-0 py-1">
                            <v-col class="pa-0" style="max-width: 100px">
                              <span>Division</span>
                            </v-col>
                            <v-col class="pa-0" align="left">
                              <span
                                >:
                                {{
                                  user.group_division
                                    ? user.group_division.division_name
                                    : ""
                                }}</span
                              >
                            </v-col>
                          </v-row>
                          <v-row class="ma-0 py-1">
                            <v-col class="pa-0" style="max-width: 100px">
                              <span>Mobile</span>
                            </v-col>
                            <v-col class="pa-0" align="left">
                              <span
                                >: {{ user.mobile ? user.mobile : "" }}</span
                              >
                            </v-col>
                          </v-row>
                          <v-row class="ma-0 py-1">
                            <v-col class="pa-0" align="left">
                              <span
                                >Is Active:
                                {{ user.active == 1 ? "Yes" : "No" }}</span
                              >
                            </v-col>
                            <v-col class="pa-0" align="left">
                              <span
                                >Is Reliever:
                                {{ user.is_reliever == 1 ? "Yes" : "No" }}</span
                              >
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-container>
            </template>

            <template v-if="new_users.length > 0">
              <h5 class="text-center">New Added Employees</h5>
              <v-container style="height: 300px; overflow-y: auto">
                <v-row class="ma-0">
                  <v-col class="pa-0 mx-3 mb-6">
                    <v-expansion-panels>
                      <v-expansion-panel
                        v-for="user in new_users"
                        :key="user.id"
                      >
                        <v-expansion-panel-header>
                          <v-row class="ma-0">
                            <v-col cols="10" class="pa-0"
                              ><span>{{ user.full_name }}</span>
                            </v-col>
                            <v-col cols="2" class="pa-0" align="right"
                              ><v-fab-transition>
                                <v-btn
                                  :to="`/employees/${user.id}`"
                                  color="blue lighten-5"
                                  dark
                                  fab
                                  x-small
                                >
                                  <v-icon color="blue">mdi-pencil</v-icon>
                                </v-btn>
                              </v-fab-transition></v-col
                            >
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row class="ma-0 py-1">
                            <v-col class="pa-0" style="max-width: 100px">
                              <span>Email</span>
                            </v-col>
                            <v-col class="pa-0" align="left">
                              <span>: {{ user.email }}</span>
                            </v-col>
                          </v-row>
                          <v-row class="ma-0 py-1">
                            <v-col class="pa-0" style="max-width: 100px">
                              <span>Mobile</span>
                            </v-col>
                            <v-col class="pa-0" align="left">
                              <span
                                >: {{ user.mobile ? user.mobile : "" }}</span
                              >
                            </v-col>
                          </v-row>
                          <v-fab-transition>
                            <v-btn
                              :to="`/employees/${user.id}/documents`"
                              color="blue lighten-5"
                              dark
                              fab
                              x-small
                            >
                              <v-icon color="blue">mdi-plus</v-icon>
                            </v-btn>
                          </v-fab-transition>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-if="users.length == 0">
              <v-row justify="center">
                <v-col align="center" style="min-height: 100px">
                  <v-progress-circular
                    v-if="isLoading == true"
                    :size="70"
                    :width="7"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                  <span v-else> No employee found. </span>
                </v-col>
              </v-row>
            </template>
            <template v-if="new_users.length == 0">
              <v-row justify="center">
                <v-col align="center" style="min-height: 100px">
                  <v-progress-circular
                    v-if="isLoading == true"
                    :size="70"
                    :width="7"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                  <span v-else> No new employee found. </span>
                </v-col>
              </v-row>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
export default {
  components: { backbutton },
  name: "UsersPage",
  data() {
    return {
      users: [],
      new_users: [],
      isLoading: false,
      sites: [],
      form: {},
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(
        `/monthly_salaries/masters?site_id=${this.site.id}`
      );
      masters = masters.data;
      masters.sites.forEach((site) => {
        this.sites.push({
          id: site.id,
          text: site.name,
          value: site.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      this.users = [];
      let users = await axios.get(
        `users?site_id=${this.form.site_id}&role_id=3`
      );
      this.users = users.data.data;

      this.new_users = [];
      let new_users = await axios.get(
        `users?site_id=${this.form.site_id}&role_id=3&is_new=yes`
      );
      this.new_users = new_users.data.data;
      this.isLoading = false;
    },
  },
};
</script>
