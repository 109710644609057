<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-6" rounded="xl">
        <v-row class="mx-4 my-2">
          <v-col class="pa-0 mt-4">
            <h5 class="text-h6" align="left">Select Client</h5>
            <v-select
              solo
              hide-details=""
              dense
              rounded
              v-model="form.site_id"
              :items="sites"
              label="Client"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="pa-0 mt-2">
            <h5 class="text-h6" align="left">Full Name*</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.full_name"
              placeholder="Full Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Email*</h5>
            <v-text-field
              type="email"
              hide-details=""
              solo
              dense
              rounded
              v-model="form.email"
              placeholder="Email"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Mobile*</h5>
            <v-text-field
              type="number"
              hide-details=""
              solo
              dense
              rounded
              v-model="form.mobile"
              placeholder="Mobile"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="ma-0 mx-4" v-if="roleName == 'Admin'">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Role</h5>
            <v-select
              hide-details=""
              solo
              dense
              rounded
              v-model="form.role_id"
              :items="roleItems"
              label="Role"
            ></v-select>
          </v-col>
        </v-row>

        <!-- <v-row class="ma-0 mx-4">
          <v-col class="pa-0 mt-4">
            <h5 class="text-h6" align="left">Is Active</h5>
          </v-col>
          <v-col class="pa-0">
            <v-checkbox hide-details="" v-model="form.active"></v-checkbox>
          </v-col>
          <v-col class="pa-0 mt-5">
            <h5 align="left" style="font-size: 18px">Is Reliever</h5>
          </v-col>
          <v-col class="pa-0">
            <v-checkbox hide-details="" v-model="form.is_reliever"></v-checkbox>
          </v-col>
        </v-row> -->

        <v-row class="mx-4 mt-6 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="update"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserEditPage",
  data() {
    return {
      form: {
        name: "",
        role_id: "",
        active: true,
      },

      roleItems: [],
      sites: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;

      masters.roles.forEach((role) => {
        this.roleItems.push({
          id: role.id,
          text: role.name,
          value: role.id,
        });
      });
      masters.sites.forEach((site) => {
        this.sites.push({
          id: site.id,
          text: site.name,
          value: site.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/users/${this.$route.params.userId}`);
      this.form = form.data.data;
      this.form.roles.length > 0
        ? (this.form.role_id = this.form.roles[0].id)
        : (this.form.role_id = "");
      this.form.sites.length > 0
        ? (this.form.site_id = this.form.sites[0].id)
        : (this.form.site_id = "");

      this.isLoading = false;
    },
    async update() {
      try {
        this.isLoading = true;
        // this.form.site_id = this.site.id;
        await axios.patch(`/users/${this.$route.params.userId}`, this.form);
        this.isLoading = false;
        this.$router.push("/employees");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
