<template>
  <v-main>
    <v-container fluid>
      <v-row justify="start">
        <v-col>
          <v-btn
            class="mx-2 mb-2"
            :loading="isSaving"
            :disabled="isSaving"
            small
            color="white"
            @click="save"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="pa-0">
          <v-col class="ma-0">
            <v-simple-table fixed-header class="ma-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">From Time</th>
                    <th class="text-left">To Time</th>
                    <th class="text-left">Daily Task</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(detail, i) in schedulerDetails"
                    :key="`detail${i}`"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>
                      <v-col class="px-0">
                        <v-autocomplete
                          solo
                          dense
                          rounded
                          hide-details=""
                          ref="user"
                          v-model="detail.from_time"
                          :items="timings"
                          label="From Time"
                        ></v-autocomplete>
                      </v-col>
                    </td>
                    <td>
                      <v-col class="px-0">
                        <v-autocomplete
                          solo
                          dense
                          rounded
                          hide-details=""
                          ref="user"
                          v-model="detail.to_time"
                          :items="timings"
                          label="To Time"
                        ></v-autocomplete>
                      </v-col>
                    </td>
                    <td>
                      <v-col class="px-0">
                        <v-autocomplete
                          solo
                          dense
                          rounded
                          hide-details=""
                          ref="user"
                          v-model="detail.daily_task_id"
                          :items="dailyTasks"
                          label="Select Daily Tasks"
                        ></v-autocomplete>
                      </v-col>
                    </td>
                    <td class="text-right">
                      <div class="text-center">
                        <v-tooltip bottom v-if="!detail.id">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="primary"
                              icon
                              @click="deleteSchedulerDetail(detail)"
                            >
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                        <v-tooltip bottom v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="primary"
                              icon
                              @click="delete_data(detail.id)"
                            >
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <v-btn
                        outlined
                        block
                        color="primary"
                        @click="addEmptySchedulerDetail()"
                      >
                        Add New Row
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "SchedulerDetailPage",
  data() {
    return {
      schedulerDetails: [],
      isLoading: false,
      isSaving: false,
      timings: [
        { text: "8:00 AM", value: "8:00 AM" },
        { text: "9:00 AM", value: "9:00 AM" },
        { text: "10:00 AM", value: "10:00 AM" },
        { text: "11:00 AM", value: "11:00 AM" },
        { text: "12:00 PM", value: "12:00 PM" },
        { text: "1:00 PM", value: "1:00 PM" },
        { text: "2:00 PM", value: "2:00 PM" },
        { text: "3:00 PM", value: "3:00 PM" },
        { text: "4:00 PM", value: "4:00 PM" },
        { text: "5:00 PM", value: "5:00 PM" },
        { text: "6:00 PM", value: "6:00 PM" },
        { text: "7:00 PM", value: "7:00 PM" },
        { text: "8:00 PM", value: "8:00 PM" },
        { text: "9:00 PM", value: "9:00 PM" },
      ],
      dailyTasks: [],
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/user_daily_summaries/masters");
      masters = masters.data;

      let dailyTasks = masters.daily_tasks;
      dailyTasks.forEach((dailyTask) => {
        this.dailyTasks.push({
          id: dailyTask.id,
          text: dailyTask.code,
          value: dailyTask.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let schedulerDetails = await axios.get(
        `/schedulers/${this.$route.params.schedulerId}/scheduler_details`
      );
      this.schedulerDetails = schedulerDetails.data.data;
      this.count = schedulerDetails.data.count;
      this.isLoading = false;
      // this.exportToExcel();
    },
    addEmptySchedulerDetail() {
      this.schedulerDetails.push({
        scheduler_id: this.$route.params.schedulerId,
        from_date: "",
        to_date: "",
        daily_task_id: "",
        is_active: 1,
      });
    },
    deleteSchedulerDetail(detail) {
      this.schedulerDetails = this.schedulerDetails.filter(
        (ar) => ar != detail
      );
    },
    async save() {
      if (this.schedulerDetails.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.schedulerDetails,
        };
        let response = await axios.post(
          `/schedulers/${this.$route.params.schedulerId}/scheduler_details_multiple`,
          payload
        );
        this.schedulerDetails = response.data.data;
        this.isSaving = false;
      }
    },
    async delete_data(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.post(`delete?id=${id}&table_name=scheduler_detsils`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
