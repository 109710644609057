<template>
  <v-app
    ref="app"
    :style="{
      background:
        $route.name == 'LoginPage'
          ? $vuetify.theme.themes.light.background
          : $vuetify.theme.themes.light.primary,
    }"
  >
    <router-view />
    <!-- <v-footer app bottom absolute padless class="justify-center" color="#292929" height="100">
      <div
        class="title font-weight-light grey--text text--lighten-1 text-center"
      >
        &copy; {{ new Date().getFullYear() }} — Mom — Made with 💜 by
        Mom Team
      </div>
    </v-footer> -->
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({}),
  mounted() {
    console.log(this.$route.name);
  },
};
</script>

<style>
.primaryColor {
  color: #0023f5 !important;
}
.secondaryColor {
  color: #e88774;
}
div {
  word-break: break-word !important;
}
.routerLink {
  text-decoration: none;
}
.color1Class {
  background-color: #fec085;
}
.color2Class {
  background-color: #82ffb4;
}
.color3Class {
  background-color: #81acfe;
}
.color4Class {
  background-color: #ffeb81;
}
.v-divider {
  border-width: 2px !important;
  border-color: black !important;
  margin-top: 10px;
  margin-bottom: 10px;
}
@font-face {
  font-family: "Fredoka One";
  src: url("../src/assets/fonts/FredokaOne-Regular.ttf");
}
@font-face {
  font-family: "SF PRO";
  src: url("../src/assets/fonts/SFPRODISPLAYREGULAR.ttf");
}
</style>
