<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0">
          <!-- Main Container -->
          <v-card class="mt-4" rounded="xl" color="blue lighten-5" width="auto">
            <!-- Dashboard Container -->
            <v-container>
              <v-row justify="center" class="ma-0">
                <v-col align="center" class="pa-0">
                  <v-img
                    :src="`${mediaUrl + newArticle.image_path}`"
                    alt="News"
                    width="auto"
                  ></v-img>
                  <!-- max-width="220" -->
                </v-col>
              </v-row>
              <v-row justify="center" class="ma-0">
                <v-col align="center" class="pa-0 mt-2">
                  <span class="text-h6">{{ newArticle.title }}</span>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0 mx-3">
                  <span class="text-subtitle-1 blue--text"
                    >{{ newArticle.edited_by }}<br />
                  </span>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0 mx-3">
                  <span class="text-subtitle-1 blue--text">{{
                    newArticle.created_at | DDMMMYYYY
                  }}</span>
                </v-col>
              </v-row>
              <!-- Category Cards -->
              <v-row class="pa-0">
                <!-- <v-col v-for="category in categories" :key="category.title"> -->
                <v-col class="bold" justify="center">
                  <p>
                    {{ newArticle.description }}
                  </p>
                </v-col>
              </v-row>

              <v-row justify="center" class="ma-0">
                <v-col
                  align="center"
                  class="pa-0"
                  v-if="newArticle.previous_article_id != null"
                >
                  <v-btn
                    :to="`/weekly-news-details/${newArticle.previous_article_id}`"
                    min-width="150"
                    color="blue"
                    rounded
                    max-width="100"
                    dark
                    >Prev</v-btn
                  >
                </v-col>
                <v-col
                  align="center"
                  class="pa-0"
                  v-if="newArticle.next_article_id != null"
                >
                  <v-btn
                    :to="`/weekly-news-details/${newArticle.next_article_id}`"
                    min-width="150"
                    color="blue"
                    rounded
                    max-width="100"
                    dark
                    >Next</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      newArticle: {},
    };
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let newArticle = await axios.get(
        `/news_articles/${this.$route.params.newsId}`
      );
      this.newArticle = newArticle.data.data;

      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.custom-border {
  border-color: #2b8fda; /* Replace with your desired border color */
}
</style>
