/* eslint-disable */
import axios from "axios";

export default {
  namespaced: true,
  state: {
    token: "",
    user: {},
    site: {},
    roleName: "",
  },
  getters: {
    authenticated(state) {
      return state.token ? true : false;
    },
    user(state) {
      return state.user;
    },
    site(state) {
      return state.site;
    },
    roleName(state) {
      return state.user.roles[0].name;
    },
    userPermissions(state) {
      let module_names = [];
      state.user.user_permissions.forEach((user_permission) => {
        module_names.push(user_permission.module_name);
      });
      return module_names;
    },
  },
  mutations: {
    SET_TOKEN(state, data) {
      state.token = data;
    },
    SET_USER(state, data) {
      state.user = data;
      let userCompanies = data ? data.sites : "";
      state.site = userCompanies.length ? userCompanies[0] : {};
    },
    SET_COMPANY(state, site) {
      state.site = site;
      axios.defaults.headers.common["sited"] = site.id;
    },
  },
  actions: {
    async logIn({ dispatch }, credentails) {
      const response = await axios.post("/login", credentails);
      await dispatch("attempt", response.data.token);
    },
    async attempt({ state, commit }, token) {
      if (token) commit("SET_TOKEN", token);

      if (!state.token) return;

      try {
        const response = await axios.get("/me");
        commit("SET_USER", response.data.data);
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },
    logOut({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
    },
    setCompany({ commit }, site) {
      commit("SET_COMPANY", site);
    },
  },
};
