<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-6" rounded="xl">
        <v-row class="mx-4 my-2">
          <v-col class="pa-0 mt-2">
            <h5 class="text-h6" align="left">Course Name*</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.course_name"
              placeholder="Course Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Course Description</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.designation"
              placeholder="Course Description"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Instructor</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.instructor"
              placeholder="Instructor"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">No of Hrs</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.no_of_hrs"
              placeholder="No of Hrs"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-6 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {},
    };
  },

  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/courses", this.form);
        this.isLoading = false;
        this.$router.push("/training-video-lists");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
