<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col>
          <backbutton></backbutton>
          <span
            class="text-h5 font-weight-bold"
            v-text="' Permission of Users'"
          ></span>
          <v-btn
            class="mx-2 mb-2"
            :loading="isSaving"
            :disabled="isSaving"
            small
            color="primary"
            @click="save"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            @change="getData"
            ref="user"
            v-model="user_id"
            :items="userItems"
            label="Select User"
            prepend-icon="mdi-format-permission-text"
            solo
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12" v-if="user_id">
          <v-card elevation="6">
            <v-simple-table
              fixed-header
              :height="`${user_permissions.length ? '370px' : ''}`"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Module Name</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(userPermission, i) in user_permissions"
                    :key="`userPermission${i}`"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>
                      <v-col class="px-0">
                        <v-autocomplete
                          v-model="userPermission.module_name"
                          :items="module_nameItems"
                          dense
                          label="Description"
                          hide-details="auto"
                          :error-messages="
                            userPermission.module_name == ''
                              ? 'this module_name field is required'
                              : ''
                          "
                        ></v-autocomplete>
                      </v-col>
                    </td>
                    <td class="text-right">
                      <div class="text-center">
                        <v-tooltip bottom v-if="!userPermission.id">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="primary"
                              icon
                              @click="deleteUserPermission(userPermission)"
                            >
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                        <v-tooltip bottom v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="primary"
                              icon
                              @click="delete_data(userPermission.id)"
                            >
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="user_id">
                    <td colspan="9">
                      <v-btn
                        outlined
                        block
                        color="primary"
                        @click="addEmptyUserPermission()"
                      >
                        Add New Row
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
export default {
  components: { backbutton },
  name: "UserPermissionsPage",
  data() {
    return {
      user_id: "",
      isSaving: false,
      userItems: [],
      module_nameItems: [],
      user_permissions: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("user_permissions/masters");
      masters = masters.data;
      masters.users.forEach((user) => {
        this.userItems.push({
          id: user.id,
          text: user.first_name + " " + user.last_name,
          value: user.id,
        });
      });
      masters.module_names.forEach((module_name) => {
        this.module_nameItems.push({
          id: module_name.id,
          text: module_name.description,
          value: module_name.description,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let user_permissions = await axios.get(
        `/users/${this.user_id}/user_permissions`
      );
      this.user_permissions = user_permissions.data.data;
      this.count = user_permissions.data.count;
      this.isLoading = false;
      // this.exportToExcel();
    },
    addEmptyUserPermission() {
      this.user_permissions.push({
        user_id: this.user_id,
        site_id: this.site.id,
        description: "",
        code: "",
        is_active: 1,
      });
    },
    deleteUserPermission(userPermission) {
      this.user_permissions = this.user_permissions.filter(
        (ar) => ar != userPermission
      );
    },
    async save() {
      if (this.user_permissions.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.user_permissions,
        };
        let response = await axios.post(
          `/users/${this.user_id}/user_permissions_multiple`,
          payload
        );
        this.user_permissions = response.data.data;
        this.isSaving = false;
      }
    },
    async delete_data(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.post(`delete?id=${id}&table_name=user_permissions`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
