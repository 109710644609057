<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-12" rounded="xl">
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Title</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.title"
              placeholder="Title"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Video URL</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.video_url"
              placeholder="Video URL"
              outlined
              class="blue--text"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col align="center">
            <v-btn
              @click="save"
              min-width="200"
              :loading="isLoading"
              color="blue"
              rounded
              max-width="100"
              dark
              >Save</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "SafetyVideos",
  data() {
    return {
      form: {},
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        let safety_video = await axios.post("/safety_videos", this.form);
        this.safety_video = safety_video.data.data;
        this.isLoading = false;
        this.$router.push("/safety-videos");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
