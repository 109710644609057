<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0 mt-2">
            <h5 class="text-h6" align="left">Date of Application</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.date_of_application"
              placeholder="Email"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Name of Employee</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.employee_name"
              placeholder="Employee Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Designation</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.designation"
              placeholder="Type"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Client Name</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.client_name"
              placeholder="Client Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Location</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.location"
              placeholder="Location"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row class="mx-4 mt-2 my-2 pb-4"> -->
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Name of Reporting Supervisor</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.name_of_reporting_supervisor"
              placeholder="Name of Reporting Supervisor"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Duration</h5>
            <v-row>
              <v-col
                ><v-text-field
                  hide-details=""
                  solo
                  dense
                  readonly
                  rounded
                  v-model="form.from_date"
                  placeholder="From Date"
                  outlined
                ></v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  hide-details=""
                  solo
                  dense
                  readonly
                  rounded
                  v-model="form.to_date"
                  placeholder="To Date"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Total no of Days</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.total_no_of_days"
              placeholder="Total no of Days"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Reason for Leave</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.reason_for_leave"
              placeholder="Reason for Leave"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="center">
              Contact Details (In Case of an Emergency)
            </h5>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Name:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.name"
              placeholder="Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Address:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.address"
              placeholder="Address"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Phone no:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.phone_no"
              placeholder="Phone No"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Signature of Employee:</h5>
            <!-- <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.supervisor.full_name"
              placeholder="Supervisor Name"
              outlined
            ></v-text-field> -->
          </v-col>
        </v-row>
        <hr class="mx-5" />
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Is Approved:</h5>
            <v-switch
              color="orange"
              v-model="form.is_approved"
              align="center"
              :readonly="roleName == 'Employee'"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2" v-if="form.approved_by != null">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Approved By:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              :readonly="roleName == 'Employee'"
              rounded
              v-model="form.approved_by.full_name"
              placeholder="Approved By"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2 pb-6" v-if="form.approved_date != null">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Approved Date:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              :readonly="roleName == 'Employee'"
              rounded
              v-model="form.approved_date"
              placeholder="Approved Date"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-6 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(
        `/leave_application_requests/${this.$route.params.leaveId}`
      );
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.approved_by_id = this.user.id;
        this.form.approved_date = moment().format("YYYY-MM-DD");
        let leave = await axios.patch(
          `/leave_application_requests/${this.form.id}`,
          this.form
        );
        this.leave = leave.data;
        this.isLoading = false;
        this.$router.push("/leave-application-forms");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>
