<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            v-if="roleName == 'Employee' || roleName == 'Supervisor'"
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="ma-0">
                <v-col class="pa-0" style="min-width: 250px"
                  ><p class="py-3 text-subtitle-1">
                    Apply New Salary Advance:
                  </p></v-col
                >
                <v-col class="pa-0">
                  <router-link to="/salary-advance-create">
                    <v-btn color="white" dark fab>
                      <v-icon color="blue lighten1">mdi-plus</v-icon>
                    </v-btn>
                  </router-link>
                </v-col>
              </v-row>

              <!-- Category Cards -->
              <template v-if="salary_requests.length > 0">
                <v-card
                  rounded="xl"
                  class="custom-border px-4 my-6"
                  elevation="10"
                  v-for="salary_request in salary_requests"
                  :key="salary_request.id"
                >
                  <router-link
                    :to="`/salary-advances/${salary_request.id}`"
                    style="text-decoration: none"
                  >
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Advance Amount:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ salary_request.advanced_amount }}
                        </span>
                      </v-col>

                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          No of Installment:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ salary_request.no_of_installment }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Is Approved:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ salary_request.is_approved == 1 ? "Yes" : "No" }}
                        </span>
                      </v-col>

                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Approved Date:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ salary_request.approved_date }}
                        </span>
                      </v-col>
                    </v-row>
                  </router-link>
                </v-card>
              </template>
              <template v-else>
                <v-row justify="center" class="pb-6">
                  <v-col align="center">
                    <span> No Salary Advance Found.</span>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card>
          <v-card
            v-if="roleName == 'Admin' || roleName == 'Supervisor'"
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="ma-0">
                <v-col class="pa-0" style="min-width: 250px"
                  ><p class="py-3 text-subtitle-1">
                    Salary Advance Applied by
                    {{ roleName == "Admin" ? "Supervisors" : "Employees" }}:
                  </p></v-col
                >
              </v-row>

              <!-- Category Cards -->
              <template v-if="employee_salary_requests.length > 0">
                <v-card
                  rounded="xl"
                  class="custom-border px-4 my-6"
                  elevation="10"
                  v-for="salary_advance in employee_salary_requests"
                  :key="salary_advance.id"
                >
                  <!-- :to="leave" -->
                  <router-link
                    :to="`/salary-advances/${salary_advance.id}`"
                    style="text-decoration: none"
                  >
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          {{
                            roleName == "Admin" ? "Supervisors" : "Employees"
                          }}
                          Name:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ salary_advance.user.full_name }}
                        </span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Employee Code:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ salary_advance.user.employee_id }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Advance Amount:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ salary_advance.advanced_amount }}
                        </span>
                      </v-col>

                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          No of Installment:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ salary_advance.no_of_installment }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Is Approved:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ salary_advance.is_approved == 1 ? "Yes" : "No" }}
                        </span>
                      </v-col>

                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Approved Date:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ salary_advance.approved_date }}
                        </span>
                      </v-col>
                    </v-row>
                  </router-link>
                </v-card>
              </template>
              <template v-else>
                <v-row justify="center" class="pb-6">
                  <v-col align="center">
                    <span> No Salary Advance Applications Found.</span>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      salary_requests: [],
      employee_salary_requests: [],
    };
  },
  mounted() {
    if (this.roleName == "Admin") {
      this.getSupervisorData();
    } else {
      this.getData();
    }
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let salary_requests = await axios.get(`salary_advance_requests`);
      this.salary_requests = salary_requests.data.data;
      this.isLoading = false;
      this.getEmployeeData();
    },
    async getEmployeeData() {
      this.isLoading = true;
      let employee_salary_requests = await axios.get(
        `salary_advance_requests?fetch_employees_leave=true`
      );
      this.employee_salary_requests = employee_salary_requests.data.data;
      this.isLoading = false;
    },
    async getSupervisorData() {
      this.isLoading = true;
      let employee_salary_requests = await axios.get(
        `salary_advance_requests?fetch_supervisors_leave=true`
      );
      this.employee_salary_requests = employee_salary_requests.data.data;
      this.isLoading = false;
    },
  },
};
</script>
