<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0 mt-2">
            <h5 class="text-h6" align="left">Date*</h5>
            <v-col class="pa-0 pt-4">
              <v-dialog
                ref="ApplicationDae"
                :close-on-content-click="false"
                v-model="date_menu"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date"
                    label="Date"
                    solo
                    dense
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date"
                  scrollable
                  @input="date_menu = false"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-col>
        </v-row>

        <v-row class="mx-4 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <v-btn
              :loading="isLoading"
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      date_menu: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      form: {
        date: "",
      },
    };
  },
  mounted() {
    this.form.date = this.$route.query.date
      ? this.$route.query.date
      : this.date;
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        await axios.post(
          `/users/${this.$route.params.employeeId}/user_daily_summaries/store_automatic`,
          this.form
        );
        this.isLoading = false;
        this.$router.push("/daily-works");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>
