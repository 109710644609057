<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="ma-0">
                <v-col class="pa-0" style="min-width: 250px"
                  ><p class="py-3 text-subtitle-1">Add Scheduler:</p></v-col
                >
                <v-col class="pa-0">
                  <router-link to="/schedulers/create">
                    <v-btn color="white" dark fab>
                      <v-icon color="blue lighten1">mdi-plus</v-icon>
                    </v-btn>
                  </router-link>
                </v-col>
              </v-row>

              <!-- Category Cards -->
              <template v-if="schedulers.length > 0">
                <v-card
                  rounded="xl"
                  class="custom-border px-4 my-6"
                  elevation="10"
                  v-for="scheduler in schedulers"
                  :key="scheduler.id"
                >
                  <!-- :to="leave" -->
                  <router-link
                    :to="`/schedulers/${scheduler.id}`"
                    style="text-decoration: none"
                  >
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0 my-4">
                        <span class="text-subtitle-1 font-weight-medium mx-4">
                          Name:
                        </span>
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ scheduler.name }}
                        </span>
                      </v-col>
                      <v-col class="pa-0 my-4">
                        <span class="text-subtitle-1 font-weight-medium mx-4">
                          Client:
                        </span>
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ scheduler.site.name }}
                        </span>
                      </v-col>
                    </v-row>

                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0 text-right mb-4">
                        <router-link
                          :to="`/schedulers/${scheduler.id}/details`"
                        >
                          <v-btn color="white" dark fab x-small>
                            <v-icon color="blue lighten1">mdi-plus</v-icon>
                          </v-btn>
                        </router-link>
                      </v-col>
                    </v-row>
                  </router-link>
                </v-card>
              </template>
              <template v-else>
                <v-row justify="center" class="pb-6">
                  <v-col align="center">
                    <span> No Scheduler Found.</span>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      schedulers: [],
    };
  },
  mounted() {
    if (this.roleName == "Admin") {
      this.getSupervisorData();
    } else {
      this.getData();
    }
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let schedulers = await axios.get(`schedulers`);
      this.schedulers = schedulers.data.data;
      this.isLoading = false;
      this.getEmployeeData();
    },
  },
};
</script>
