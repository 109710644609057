<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          Update Value
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                prepend-inner-icon="mdi-format-list-text"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            color="primary"
            @click="save"
          >
            Update Value
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "ValueEditPage",
  data() {
    return {
      form: {
        name: "",
        role_id: 4,
        active: true,
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/values/${this.$route.params.valueId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.patch(`/values/${this.$route.params.valueId}`, this.form);
        this.isLoading = false;
        this.$router.push("/values");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
