<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="mx-4 mt-2">
          <v-col class="pa-0 mt-4">
            <v-btn
              rounded
              class="btn btn primary"
              align="left"
              @click="isReliever = !isReliever"
            >
              {{
                isReliever == true ? "Add attendance" : " Add Reliever"
              }}</v-btn
            ></v-col
          ></v-row
        >

        <template v-if="isReliever == false">
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0 mt-4">
              <h5 class="text-h6" align="left">Date</h5>

              <v-dialog
                ref="Date"
                :close-on-content-click="false"
                v-model="date_menu"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date"
                    label="Date"
                    readonly
                    solo
                    hide-details=""
                    dense
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date"
                  scrollable
                  @input="date_menu = false"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Time</h5>

              <v-dialog
                ref="dialog"
                v-model="time_modal"
                :return-value.sync="form.time"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedTime"
                    label="Time"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                    hide-details=""
                    dense
                    rounded
                  ></v-text-field>
                </template>
                <v-time-picker
                  ampm-in-title
                  v-if="time_modal"
                  v-model="form.time"
                  full-width
                  @change="updateTime"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="time_modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(form.time)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Select Client</h5>
              <v-select
                solo
                hide-details=""
                dense
                rounded
                v-model="form.site_id"
                :items="sites"
                label="Client"
                @change="getSiteWiseEmployees()"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Employee</h5>
              <v-select
                solo
                hide-details=""
                dense
                rounded
                v-model="form.user_id"
                :items="employees"
                label="Employee"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">State</h5>
              <v-select
                solo
                hide-details=""
                dense
                rounded
                v-model="form.state"
                :items="states"
                label="State"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Session Type</h5>
              <v-select
                solo
                hide-details=""
                dense
                rounded
                v-model="form.session_type_id"
                :items="sessionsTypes"
                label="Session Type"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Remark:</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                v-model="form.remark"
                placeholder="Remark"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Address:</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                v-model="form.address"
                placeholder="Address"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-4 mt-6 my-2 pb-6">
            <v-col align="center" class="pa-0">
              <!-- :loading="isLoading" -->
              <!-- @click="login" -->
              <v-btn
                min-width="250"
                color="blue"
                rounded
                max-width="100"
                dark
                @click="save"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </template>
        <template v-if="isReliever == true">
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0 mt-4">
              <h5 class="text-h6" align="left">Date</h5>

              <v-dialog
                ref="Date"
                :close-on-content-click="false"
                v-model="date_menu"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date"
                    label="Date"
                    readonly
                    solo
                    dense
                    hide-details=""
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date"
                  scrollable
                  @input="date_menu = false"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Select Client</h5>
              <v-select
                solo
                hide-details=""
                dense
                rounded
                v-model="form.site_id"
                :items="sites"
                label="Client"
                @change="getSiteWiseEmployees()"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Employee</h5>
              <v-select
                solo
                hide-details=""
                dense
                rounded
                v-model="form.user_id"
                :items="employees"
                label="Employee"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Reliever</h5>
              <v-select
                solo
                hide-details=""
                dense
                rounded
                v-model="form.reliever_id"
                :items="Relieverusers"
                label="Reliever"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Description:</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                v-model="form.description"
                placeholder="Description"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-6 my-2 pb-6">
            <v-col align="center" class="pa-0">
              <v-btn
                min-width="250"
                color="blue"
                rounded
                max-width="100"
                dark
                @click="save"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      form: {
        user_id: "",
        state: "",
        date: "",
        time: "",
        session_type_id: "",
      },
      time_modal: false,
      time_menu: false,
      days: 0,
      date_menu: false,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      employees: [],
      sessionsTypes: [],
      states: [
        { id: "IN", value: "IN", text: "IN" },
        { id: "OUT", value: "OUT", text: "OUT" },
      ],
      Relieverusers: [],
      isReliever: false,
      sites: [],
    };
  },
  mounted() {
    // this.form.site_id = this.site.id;
    this.getMasters();
  },
  computed: {
    formattedTime: {
      get() {
        return this.form.time
          ? moment(this.form.time, "HH:mm").format("hh:mm A")
          : "";
      },
      set(value) {
        this.form.time = moment(value, "hh:mm A").format("HH:mm");
      },
    },
  },

  methods: {
    updateTime(value) {
      this.form.time = moment(value, "hh:mm A").format("HH:mm");
    },
    async getMasters() {
      let masters = await axios.get(
        `/monthly_salaries/masters?site_id=${this.site.id}`
      );
      masters = masters.data;

      // masters.users.forEach((user) => {
      //   this.employees.push({
      //     id: user.id,
      //     text: user.full_name,
      //     value: user.id,
      //   });
      // });

      masters.relievers.forEach((user) => {
        this.Relieverusers.push({
          id: user.id,
          text: user.full_name,
          value: user.id,
        });
      });

      masters.session_types.forEach((sessionType) => {
        this.sessionsTypes.push({
          id: sessionType.id,
          text: sessionType.description,
          value: sessionType.id,
        });
      });
      masters.sites.forEach((site) => {
        this.sites.push({
          id: site.id,
          text: site.name,
          value: site.id,
        });
      });
    },
    // async getMasters() {
    //   let masters = await axios.get("/monthly_salaries/masters");
    //   masters = masters.data;

    //   this.configurations = masters.configurations;
    //   this.categories = masters.categories;
    //   this.groups = masters.groups;
    //   masters.users.forEach((user) => {
    //     this.Relieverusers.push({
    //       id: user.id + " - " + user.sites[0].id,
    //       name: user.full_name,
    //     });
    //   });
    //   this.refreshData();
    // },
    async save() {
      this.isLoading = true;
      this.form.source = "PORTAL";
      if (this.rawdate) {
        let date = moment(this.rawdate, "YYYY-MM-DD").format("YYYY-MM-DD");
        this.form.date = date;
      }
      if (this.rawtime) {
        let time = moment(this.rawtime, "HH:mm:ss").format("hh:mm A");
        this.form.time = time;
      }

      try {
        await axios.post(`/users/${this.form.user_id}/user_punches`, this.form);

        this.isLoading = false;
        this.$router.push(`/attendances`);
      } catch (e) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async addReliever() {
      try {
        this.isLoading = true;
        if (this.rawdate) {
          let date = moment(this.rawdate, "YYYY-MM-DD").format("YYYY-MM-DD");
          this.form.date = date;
        }

        await axios.post("/user_attendance_relievers", this.form);
        this.$router.push(`/attendances`);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
      // this.form = {
      //   reliever_id: "",
      // };
    },

    async getgroups() {
      this.groups = [];
      let groups = await axios.get(`/groups?site_id=${this.form.site_id}`);
      groups.data.data.forEach((group) => {
        this.groups.push({
          id: group.id,
          text: group.name,
          value: group.id,
        });
      });

      // console.log(this.groups);
    },

    async getSiteWiseEmployees() {
      this.employees = [];
      let employees = await axios.get(`/users?site_id=${this.form.site_id}`);
      employees.data.data.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.full_name,
          value: employee.id,
        });
      });

      // console.log(this.groups);
    },
  },
};
</script>
