<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="pt-4 ma-0">
                <v-col class="pa-0" style="max-width: 135px">
                  <span class="text-h6">Client Name: </span>
                </v-col>
                <v-col class="pa-0">
                  <span class="blue--text title">
                    {{ this.user.full_name }}
                  </span>
                </v-col>
              </v-row>

              <p class="py-3 text-subtitle-1">Select your type:</p>
              <!-- Category Cards -->
              <v-card
                rounded="xl"
                class="custom-border px-4 my-6"
                elevation="10"
                v-for="letterType in LetterTypes"
                :key="letterType.id"
              >
                <v-row align="center">
                  <v-col cols="3">
                    <v-img
                      :src="letterType.image"
                      alt="Salary Advance"
                      width="40"
                      height="34"
                    ></v-img>
                  </v-col>

                  <v-col>
                    <span class="text-subtitle-1 font-weight-medium">
                      {{ letterType.title }}
                    </span>
                  </v-col>
                </v-row>
              </v-card>

              <v-row justify="center">
                <v-col align="center">
                  <v-img
                    :src="require('../../assets/voe_imgs/letter-demo.png')"
                    alt="Logo"
                    width="180"
                    height="180"
                  ></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      LetterTypes: [
        {
          title: "Joining Letter",
          image: require("@/assets/voe_imgs/joining-letter.png"),
        },
        {
          title: "Warning Letter",
          image: require("@/assets/voe_imgs/warning.png"),
        },
        {
          title: "Termination Letter",
          image: require("@/assets/voe_imgs/termination-letter.png"),
        },
        {
          title: "Agreement",
          image: require("@/assets/voe_imgs/agreement.png"),
        },
        {
          title: "Breakup",
          image: require("@/assets/voe_imgs/breakup.png"),
        },
      ],
    };
  },
};
</script>
<style scoped>
.custom-border {
  border-color: #ff0000; /* Replace with your desired border color */
}
</style>
