<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
            min-height="430"
          >
            <v-row class="ma-0">
              <v-col class="pa-0">
                <h2 class="mx-4 my-4" style="width: 250px">
                  Visiting Report Lists
                </h2>
              </v-col>
              <v-col class="pa-0 ma-2">
                <v-fab-transition>
                  <v-btn
                    to="/visiting-reports/create"
                    color="blue lighten-5"
                    dark
                    absolute
                    fab
                    width="50"
                    height="50"
                  >
                    <v-icon color="blue">mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
              </v-col>
            </v-row>
            <template v-if="visiting_reports.length > 0">
              <v-row class="ma-0">
                <v-col class="pa-0 mx-3 mb-6">
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="visiting_reoprt in visiting_reports"
                      :key="visiting_reoprt.id"
                    >
                      <v-expansion-panel-header>
                        <v-row class="ma-0">
                          <v-col cols="10" class="pa-0"
                            ><span>{{ visiting_reoprt.client_name }}</span>
                          </v-col>
                          <v-col cols="2" class="pa-0" align="right"
                            ><v-fab-transition>
                              <v-btn
                                :to="`/visiting-reports/${visiting_reoprt.id}`"
                                color="blue lighten-5"
                                dark
                                fab
                                x-small
                              >
                                <v-icon color="blue">mdi-pencil</v-icon>
                              </v-btn>
                            </v-fab-transition></v-col
                          >
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="ma-0 py-1">
                          <v-col class="pa-0" style="max-width: 100px">
                            <span>Location</span>
                          </v-col>
                          <v-col class="pa-0" align="left">
                            <span>: {{ visiting_reoprt.location }}</span>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 py-1">
                          <v-col class="pa-0" style="max-width: 100px">
                            <span>Description</span>
                          </v-col>
                          <v-col class="pa-0" align="left">
                            <span>: {{ visiting_reoprt.description }}</span>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 py-1">
                          <v-col class="pa-0" style="max-width: 100px">
                            <span>Designation</span>
                          </v-col>
                          <v-col class="pa-0" align="left">
                            <span>: {{ visiting_reoprt.designation }}</span>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 py-1">
                          <v-col class="pa-0" style="max-width: 100px">
                            <span>Mobile No</span>
                          </v-col>
                          <v-col class="pa-0" align="left">
                            <span
                              >:
                              {{
                                visiting_reoprt.mobile_no
                                  ? visiting_reoprt.mobile_no
                                  : ""
                              }}</span
                            >
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 py-1">
                          <v-col class="pa-0" style="max-width: 100px">
                            <span>Date</span>
                          </v-col>
                          <v-col class="pa-0" align="left">
                            <span
                              >:
                              {{ visiting_reoprt.created_at | ddmmyyyy }}</span
                            >
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </template>
            <template v-if="visiting_reports.length == 0">
              <v-row justify="center">
                <v-col align="center" style="min-height: 100px">
                  <v-progress-circular
                    v-if="isLoading == true"
                    :size="70"
                    :width="7"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                  <span v-else> No employee found. </span>
                </v-col>
              </v-row>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
export default {
  components: { backbutton },
  name: "UsersPage",
  data() {
    return {
      visiting_reports: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let visiting_reports = await axios.get(`/feedbacks`);
      this.visiting_reports = visiting_reports.data.data;
      this.isLoading = false;
    },
  },
};
</script>
