<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="ma-0">
                <v-col class="pa-0" style="min-width: 166px">
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-3">
                      <v-toolbar-title>Budget: </v-toolbar-title>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-select
                        solo
                        dense
                        rounded
                        hide-details=""
                        v-model="name"
                        :items="budgets"
                        label="Budget"
                        @change="getData()"
                      ></v-select>
                      <!-- @change="updateCalendar" -->
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pa-0" style="min-width: 166px">
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-4">
                      <v-toolbar-title>Site Name: </v-toolbar-title>
                    </v-col>
                  </v-row>

                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-select
                        solo
                        dense
                        rounded
                        hide-details=""
                        v-model="group_id"
                        :items="siteNames"
                        label="Site Name"
                        @change="getData()"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <template v-if="isLoading == true">
                <v-row class="ma-0">
                  <v-progress-linear
                    indeterminate
                    color="primary"
                  ></v-progress-linear>
                </v-row>
              </template>
              <template v-else>
                <v-row class="ma-0">
                  <v-col cols="12" class="pl-0 pb-2">
                    <v-card rounded="r-xl" min-width="340">
                      <v-simple-table height="200px">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                class="text-left blue--text"
                                style="min-width: 56px"
                              >
                                Sr. No
                              </th>
                              <th
                                class="text-left blue--text"
                                style="min-width: 65px"
                              >
                                Name
                              </th>
                              <th
                                class="text-left blue--text"
                                style="min-width: 60px"
                              >
                                Per Rate
                              </th>
                              <th
                                class="text-left blue--text"
                                style="min-width: 60px"
                              >
                                Qty
                              </th>
                              <th
                                class="text-left blue--text"
                                style="min-width: 60px"
                              >
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                budget, at
                              ) in single_material_request.material_request_details"
                              :key="`name${at}`"
                            >
                              <td>{{ at + 1 }}</td>
                              <td style="min-width: 140px">
                                {{ budget.name }}
                              </td>
                              <td>{{ budget.per_unit_price }}</td>
                              <td>{{ budget.qty }}</td>
                              <td style="min-width: 70px">
                                {{ budget.total }}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th
                                class="text-left blue--text"
                                colspan="3"
                                style="min-width: 80px"
                              >
                                Total
                              </th>
                              <th class="text-left blue--text">
                                {{ single_material_request.qty }}
                              </th>
                              <th
                                class="text-left blue--text"
                                style="min-width: 70px"
                              >
                                {{ single_material_request.total }}
                              </th>
                            </tr>
                          </tfoot>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
              <v-row class="ma-0 my-2">
                <v-col
                  class="pa-0 mx-2"
                  align="center"
                  v-if="single_material_request.status == 0"
                >
                  <v-btn
                    color="blue"
                    rounded
                    min-width="150"
                    dark
                    @click="update(single_material_request, 1)"
                    >Approve</v-btn
                  >
                </v-col>
                <v-col
                  class="pa-0 mx-2"
                  align="center"
                  v-if="single_material_request.status == 1"
                >
                  <v-btn
                    color="red"
                    rounded
                    min-width="150"
                    dark
                    @click="update(single_material_request, 0)"
                    >Reject</v-btn
                  >
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0 mt-4" cols="12" align="center">
                  <v-img
                    :src="
                      require('../../assets/voe_imgs/material-request-demo.png')
                    "
                    alt="Logo"
                    width="180"
                    height="200"
                  ></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      budgets: [],
      siteNames: [],
      material_requests: [],
      single_material_request: {},
      name: "",
      group_id: "",
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    // this.getData();
  },
  methods: {
    async getMasters() {
      let groups = await axios.get("material_requests/masters");
      groups = groups.data;
      groups.groups.forEach((group) => {
        this.siteNames.push({
          id: group.id,
          text: group.name,
          value: group.id,
        });
      });

      let masters = await axios.get(`material_requests`);
      masters = masters.data;
      masters.data.forEach((budget) => {
        this.budgets.push({
          id: budget.id,
          text: budget.name,
          value: budget.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      this.name = this.name != "" ? this.name : "";
      this.group_id = this.group_id != "" ? this.group_id : "";
      let material_requests = await axios.get(
        `material_requests?name=${this.name}&group_id=${this.group_id}`
      );
      this.material_requests = material_requests.data.data;
      this.single_material_request = {};
      this.material_requests.forEach((material_request) => {
        this.single_material_request = material_request;
      });
      this.isLoading = false;
      console.log(this.single_material_request);
    },
    async update(material_request, is_approve) {
      material_request.status = is_approve;
      try {
        this.isLoading = true;
        await axios.patch(
          `/material_requests/${material_request.id}`,
          material_request
        );
        this.isLoading = false;
        this.$router.push("/client-material-requests");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
