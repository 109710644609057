<template>
  <v-main>
    <v-container fluid>
      <!-- Header -->
      <v-row justify="center">
        <v-col cols="12" class="px-0 pb-0 px-2">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <h3 class="text-center">OPERATIONS VISIT REPORT</h3>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Site Details -->
      <v-row>
        <v-col cols="12">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-text>
              <v-row class="px-2">
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details=""
                    solo
                    dense
                    rounded
                    v-model="form.location"
                    placeholder="Name of Location"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details=""
                    solo
                    dense
                    rounded
                    v-model="form.supervisor"
                    placeholder="Name of Supervisor"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details=""
                    solo
                    dense
                    rounded
                    v-model="form.city"
                    placeholder="Name of City"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-dialog
                    ref="VisitDate"
                    :close-on-content-click="false"
                    v-model="date_of_visit_menu"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.date_of_visit"
                        label="Date of Visit"
                        solo
                        dense
                        hide-details=""
                        rounded
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.date_of_visit"
                      scrollable
                      @input="date_of_visit_menu = false"
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-dialog
                    ref="VisitDate"
                    :close-on-content-click="false"
                    v-model="date_of_next_visit_menu"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.date_of_next_visit"
                        label="Date of Next Visit"
                        solo
                        dense
                        hide-details=""
                        rounded
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.date_of_next_visit"
                      scrollable
                      @input="date_of_next_visit_menu = false"
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-dialog
                    ref="InTimedialog"
                    v-model="in_time_modal"
                    :return-value.sync="form.in_time"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedInTime"
                        label="In Time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        solo
                        hide-details
                        dense
                        rounded
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      ampm-in-title
                      v-if="in_time_modal"
                      v-model="form.in_time"
                      full-width
                      @change="updateInTime"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="in_time_modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.InTimedialog.save(form.in_time)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12" sm="2">
                  <v-dialog
                    ref="OutTimedialog"
                    v-model="out_time_modal"
                    :return-value.sync="form.out_time"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedOutTime"
                        label="Out Time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        solo
                        hide-details
                        dense
                        rounded
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      ampm-in-title
                      v-if="out_time_modal"
                      v-model="form.out_time"
                      full-width
                      @change="updateOutTime"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="out_time_modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.OutTimedialog.save(form.out_time)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" class="px-0 pb-0 px-2">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <h3 class="text-center">
                    PHYSICAL OBSERVATION & ACTIONS TAKEN BY SUP. & CERTIFIED BY
                    BRANCH (AUTHO. SIGN.)
                  </h3>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Observations & Actions -->
      <v-row>
        <v-col cols="12">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-text>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">Particulars</th>
                    <th class="text-left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CLEANING KIT</td>
                    <td>
                      <v-text-field
                        hide-details=""
                        solo
                        dense
                        rounded
                        v-model="form.cleaning_kit_remark"
                        placeholder=""
                        outlined
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>DUSTERS</td>
                    <td>
                      <v-text-field
                        hide-details=""
                        solo
                        dense
                        rounded
                        v-model="form.dusters_remark"
                        placeholder=""
                        outlined
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>HAND LIQUID DISPENSER</td>
                    <td>
                      <v-text-field
                        hide-details=""
                        solo
                        dense
                        rounded
                        v-model="form.hand_liquid_dispenser_remark"
                        placeholder=""
                        outlined
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>MATERIAL DILUTION CHART</td>
                    <td>
                      <v-text-field
                        hide-details=""
                        solo
                        dense
                        rounded
                        v-model="form.material_dilution_chart_remark"
                        placeholder=""
                        outlined
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>WASHROOM CLEANING BUCKET</td>
                    <td>
                      <v-text-field
                        hide-details=""
                        solo
                        dense
                        rounded
                        v-model="form.wasing_cleaning_bucket_remark"
                        placeholder=""
                        outlined
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-title class="headline">Material Checklist</v-card-title>
            <v-card-text>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">Material</th>
                    <th class="text-left">Yes/No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CHEMICALS</td>
                    <td>
                      <v-radio-group v-model="form.is_chemical">
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td>CONSUMABLE</td>
                    <td>
                      <v-radio-group v-model="form.is_consumable">
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td>MACHINES</td>
                    <td>
                      <v-radio-group v-model="form.is_machines">
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Staff Details -->
      <v-row>
        <v-col cols="12">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-title class="headline">Staff Attendance</v-card-title>
            <v-card-text>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">STAFF DETAILS</th>
                    <th class="text-left">Absent/Present</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>SUPERVISORS</td>
                    <td>
                      <v-radio-group v-model="form.is_supervisors">
                        <v-radio label="Present" value="1"></v-radio>
                        <v-radio label="Absent" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td>HOUSEKEEPING BOYS</td>
                    <td>
                      <v-radio-group v-model="form.is_housekeeping_boys">
                        <v-radio label="Present" value="1"></v-radio>
                        <v-radio label="Absent" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td>CHAMBERMAIDS</td>
                    <td>
                      <v-radio-group v-model="form.is_chambermaids">
                        <v-radio label="Present" value="1"></v-radio>
                        <v-radio label="Absent" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td>OFFICE BOYS</td>
                    <td>
                      <v-radio-group v-model="form.is_office_boys">
                        <v-radio label="Present" value="1"></v-radio>
                        <v-radio label="Absent" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td>PANTRY BOYS</td>
                    <td>
                      <v-radio-group v-model="form.is_pantry_boys">
                        <v-radio label="Present" value="1"></v-radio>
                        <v-radio label="Absent" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td>TECHNICIANS</td>
                    <td>
                      <v-radio-group v-model="form.is_technicians">
                        <v-radio label="Present" value="1"></v-radio>
                        <v-radio label="Absent" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total Present</td>
                    <td></td>
                  </tr>
                </tfoot>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Documents & Registers -->
      <v-row>
        <v-col cols="12">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-title class="headline">Documents & Registers</v-card-title>
            <v-card-text>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">DOCUMENTS & REGISTERS</th>
                    <th class="text-left">Yes/No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ATTENDANCE REGISTERS AVAILABLE & SIGNED BY BOYS</td>
                    <td>
                      <v-radio-group v-model="form.is_attendance_registers">
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td>BOYS WEARING ID CARD ON DUTY</td>
                    <td>
                      <v-radio-group v-model="form.is_boys_wearing_id">
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td>HOUSEKEEPING MATERIAL REGISTER</td>
                    <td>
                      <v-radio-group
                        v-model="form.is_housekeeping_material_register"
                      >
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td>SUPERVISOR CHECKLIST PROPERLY FILLED</td>
                    <td>
                      <v-radio-group v-model="form.is_supervisor_checklist">
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td>WASHROOM CLEANING SHEET SIGNED BY BOYS</td>
                    <td>
                      <v-radio-group v-model="form.is_washroom_cleaning_sheet">
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="0"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Observations and Other Notes -->
      <v-row>
        <v-col cols="12">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-title class="headline">Particulars</v-card-title>
            <v-card-text>
              <div style="overflow-x: auto">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th class="text-left" style="min-width: 300px">
                        PARTICULARS
                      </th>
                      <th class="text-center" style="min-width: 200px">
                        Yes/No
                      </th>
                      <th class="text-left" style="min-width: 200px">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in particulars" :key="index">
                      <td>{{ item.text }}</td>
                      <td class="text-center">
                        <v-radio-group v-model="item.answer" row>
                          <v-radio label="Yes" value="1" class="mx-2"></v-radio>
                          <v-radio label="No" value="0" class="mx-2"></v-radio>
                        </v-radio-group>
                      </td>
                      <td>
                        <v-menu
                          v-model="item.dateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              v-model="item.date"
                              label="Select Date"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.date"
                            @input="item.dateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Maintenance Admin -->
      <v-row justify="center">
        <v-col cols="12" class="px-0 pb-0 px-2">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <h3 class="text-center">
                    MAINTENANCE RELATED OBSERVATIONS BY SUPERVISOR CONVEYED TO
                    ADMIN.:
                  </h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details=""
                    solo
                    dense
                    rounded
                    v-model="form.maintenance_admin"
                    placeholder=""
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" class="px-0 pb-0 px-2">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <h3 class="text-center">NEXT WEEK HK BOYS WORKS:</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details=""
                    solo
                    dense
                    rounded
                    v-model="form.next_week_work"
                    placeholder=""
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" class="px-0 pb-0 px-2">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <h3 class="text-center">
                    SIGN & STAMP BY MANAGER OR AUTHORISED SIGN. AS CERTIFIED
                    VISIT & ACTIVITY OF HK TEAM
                  </h3>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Footer for Signatures -->
      <v-row>
        <v-col cols="12">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details=""
                    solo
                    dense
                    rounded
                    v-model="form.admin_name"
                    placeholder="Admin Name"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <h5 class="text-h6" align="left">Admin Signature:</h5>
                  <input
                    class="mt-4"
                    type="file"
                    name="signature_path"
                    ref="signature_path"
                    id=""
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details=""
                    solo
                    dense
                    rounded
                    v-model="form.area_manager"
                    placeholder="Area Manager"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <h5 class="text-h6" align="left">Area Manager Signature:</h5>
                  <input
                    class="mt-4"
                    type="file"
                    name="signature_path"
                    ref="signature_path"
                    id=""
                  />
                </v-col>
                <!-- <v-col cols="12" sm="6">
                  <v-text-field
                    label="Area Manager"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Signature" dense outlined></v-text-field>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" class="px-0 pb-0 px-2">
          <v-card rounded="xl" color="blue lighten-5" width="auto">
            <v-card-text>
              <v-row class="my-2">
                <v-col align="center" class="pa-0">
                  <!-- :loading="isLoading" -->
                  <!-- @click="login" -->
                  <v-btn
                    min-width="250"
                    color="blue"
                    rounded
                    max-width="100"
                    dark
                    @click="save"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="footer-text mt-8">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import moment from "moment";

export default {
  name: "OperationsVisitReport",
  data() {
    return {
      form: {
        in_time: "",
        out_time: "",
      },
      date_of_visit_menu: false,
      date_of_next_visit_menu: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      in_time_modal: false,
      out_time_modal: false,
      particulars: [
        {
          text: "ALL A.C GRILLS ARE CLEAN",
          answer: "",
          date: "",
          dateMenu: false,
          is_all_ac_grills_are_clean: "",
        },
        {
          text: "ALL ELECTRONIC EQUIPMENTS LOOK CLEAN",
          answer: "",
          date: "",
          dateMenu: false,
          is_all_electronic_equipments_looks_clean: "",
        },
        {
          text: "ALL FURNITURE DUST FREE",
          answer: "",
          date: "",
          dateMenu: false,
          is_all_furniture_dust_free: "",
        },
        {
          text: "CHAIRS DRY CLEANING & CHAIRS PEDESTAL ARE CLEAN",
          answer: "",
          date: "",
          dateMenu: false,
          is_chairs_dry_cleaning_and_chairs_pedestal_are_clean: "",
        },
        {
          text: "COBWEBS REMOVED",
          answer: "",
          date: "",
          dateMenu: false,
          is_cobwebs_removed: "",
        },
        {
          text: "DEEP CLEANING OF ALL WORK STATIONS DONE",
          answer: "",
          date: "",
          dateMenu: false,
          is_deep_cleaning_of_all_work_stations_done: "",
        },
        {
          text: "DEEP CLEANING OF CABINS DONE",
          answer: "",
          date: "",
          dateMenu: false,
          is_deep_cleaning_of_cabins_done: "",
        },
        {
          text: "DEEP CLEANING OF PANTRY & CAFETERIA DONE",
          answer: "",
          date: "",
          dateMenu: false,
          is_deep_cleaning_of_pantry_and_cafeteria_done: "",
        },
        {
          text: "DEEP CLEANING OF WASHROOMS DONE",
          answer: "",
          date: "",
          dateMenu: false,
          is_deep_cleaning_of_washrooms_done: "",
        },
        {
          text: "DOOR MATE WASHING DONE",
          answer: "",
          date: "",
          dateMenu: false,
          is_door_mate_washing_done: "",
        },
        {
          text: "FLOOR SCRUBBING & BUFFING ACTIVITY DONE",
          answer: "",
          date: "",
          dateMenu: false,
          is_floor_scrubbing_and_buffing_activity_done: "",
        },
        {
          text: "OUTSIDE AREA IS KEPT CLEAN",
          answer: "",
          date: "",
          dateMenu: false,
          is_outside_area_is_kept_clean: "",
        },
        {
          text: "SPECIAL CLEANING DONE IN FRONT OF SUPERVISOR",
          answer: "",
          date: "",
          dateMenu: false,
          is_special_cleaning_done_in_front_of_supervisor: "",
        },
        {
          text: "WALLS & CEILING LOOK CLEAN",
          answer: "",
          date: "",
          dateMenu: false,
          is_walls_and_ceiling_looks_clean: "",
        },
      ],
    };
  },
  computed: {
    formattedInTime: {
      get() {
        return this.form.in_time
          ? moment(this.form.in_time, "HH:mm").format("hh:mm A")
          : "";
      },
      set(value) {
        this.form.in_time = moment(value, "hh:mm A").format("HH:mm");
      },
    },
    formattedOutTime: {
      get() {
        return this.form.out_time
          ? moment(this.form.out_time, "HH:mm").format("hh:mm A")
          : "";
      },
      set(value) {
        this.form.out_time = moment(value, "hh:mm A").format("HH:mm");
      },
    },
  },
  methods: {
    updateInTime(value) {
      this.form.in_time = moment(value, "HH:mm").format("HH:mm");
    },
    updateOutTime(value) {
      this.form.out_time = moment(value, "HH:mm").format("HH:mm");
    },
    async save() {
      console.log("kjhgf");

      //   try {
      // this.isLoading = true;
      console.log(this.form);
      console.log(this.particulars);
      // this.form.user_id = this.user.id;
      // let leave = await axios.post("/leave_application_requests", this.form);
      // this.leave = leave.data;
      // this.handleFileUpload();
      // this.isLoading = false;
      // this.$router.push("/request-forms");
      //   } catch (e) {
      //     console.log(e);
      //     this.isLoading = false;
      //   }
    },
  },
};
</script>

<style scoped>
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-sm-right {
  text-align: right;
}

.text-sm-left {
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .text-sm-right {
    text-align: left;
  }

  .text-sm-left {
    text-align: left;
  }
}
</style>
